import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localization } from '../../../assets/Localization';
import { getCookie } from '../../../lib/cookieUtils';
import { IPaymentCurrencySwitch, PaymentFrequency } from '../../../types/PaymentFrequency';
import { setPaymentCurrencySwitch } from '../../../store/entities/paymentCurrencySwitch';

const getQueryStringWithUpdatedParameter = (queryString: string, param: string, value: string): string => {
  if (!queryString.includes(param)) {
    return `${queryString}${queryString.length > 0 ? '&' : ''}${param}=${encodeURIComponent(value)}`;
  } else {
    return queryString.replace(new RegExp('(' + param + '=)[^&]+'), `$1${encodeURIComponent(value)}`);
  }
};

export const updatePaymentFrequencyAndCurrency = (input: {
  paymentFrequency: PaymentFrequency;
  currency: string;
}): void => {
  document.querySelectorAll('[data-price],[data-update-program]').forEach((element) => {
    if (
      element.getAttribute('data-payment-frequency') === input.paymentFrequency &&
      element.getAttribute('data-currency') === input.currency
    ) {
      element.classList.remove('cookie-banner--hide');
    } else {
      element.classList.add('cookie-banner--hide');
    }
  });
};

export const updateHistoryWithPaymentFrequencyAndCurrency = (input: {
  paymentFrequency: PaymentFrequency;
  currency: string;
}): void => {
  if (window.history) {
    let newQueryString = getQueryStringWithUpdatedParameter(
      window.location.search,
      'paymentFrequency',
      input.paymentFrequency,
    );
    newQueryString = getQueryStringWithUpdatedParameter(newQueryString, 'currency', input.currency);

    window.history.replaceState({}, '', newQueryString);
  }
};

const PaymentFrequencies: React.FC<{ paymentFrequency: PaymentFrequency | null; currency: string | null }> = ({
  currency,
}) => {
  const paymentCurrencySwitch = useSelector((state: IPaymentCurrencySwitch) => state.paymentCurrencySwitch);
  const dispatch = useDispatch();
  const language = getCookie('locale');

  const paymentFrequencyHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    if (currency != null) {
      dispatch(setPaymentCurrencySwitch({ paymentFrequency: event.currentTarget.id as PaymentFrequency }));
      const input = {
        paymentFrequency: event.currentTarget.id as PaymentFrequency,
        currency: paymentCurrencySwitch.currency,
      };

      updateHistoryWithPaymentFrequencyAndCurrency(input);
      updatePaymentFrequencyAndCurrency(input);
    }
  };

  return (
    <>
      {Object.values(PaymentFrequency).map((el) => (
        <a
          key={el}
          href="#"
          onClick={paymentFrequencyHandler}
          className={`${paymentCurrencySwitch.paymentFrequency === el ? 'active' : ''}`}
          id={el}
        >{`${
          el.includes(PaymentFrequency.MONTHLY)
            ? localization[language || 'en-US'].monthly
            : localization[language || 'en-US'].oneTime
        }`}</a>
      ))}
    </>
  );
};

export default PaymentFrequencies;

