import { createSlice } from '@reduxjs/toolkit';
import { CookieType } from '../../types/Cookie';
import { deleteCookie } from '../../lib/cookieUtils';

const orders = createSlice({
  name: 'orders',
  initialState: {
    orderLoading: true,
    order: null,
    error: null,
    count: 0,
    details: null,
    legalTexts: null,
    orderStatus: null,
    isSubscribed: false,
    subscriptionLoading: false,
  },
  reducers: {
    getOrder(state, _action) {
      return { ...state, orderLoading: true };
    },
    setOrder(state, action) {
      const data = action.payload;
      return { ...state, order: data, orderLoading: false };
    },
    createOrder(state, _action) {
      return { ...state, orderLoading: true };
    },
    updateOrder(state, _action) {
      return { ...state, updateLoading: true };
    },
    changeCurrency(state, _action) {
      return { ...state, orderLoading: true };
    },
    itemQuantityChange(state, _action) {
      return { ...state, orderLoading: true };
    },
    setOrderError(state, action) {
      const data = action.payload;
      if ([403, 404].includes(data.response?.status)) deleteCookie(CookieType.SHOPPING_CART);
      return { ...state, error: data, orderLoading: false, count: 0 };
    },
    getOrderDetails(state, _action) {
      return { ...state, orderLoading: true };
    },
    setOrderDetails(state, action) {
      const data = action.payload;
      return { ...state, details: data, order: data.shoppingCart, orderStatus: data.status, orderLoading: false };
    },
    getOrderLegalTexts(state, _action) {
      return { ...state };
    },
    setOrderLegalTexts(state, action) {
      const data = action.payload;
      return { ...state, legalTexts: data, orderLoading: false };
    },
    checkoutOrder(state, _action) {
      return { ...state, orderLoading: true };
    },
    getOrderStatus(state, _action) {
      return { ...state, orderLoading: true };
    },
    setOrderStatus(state, action) {
      const data = action.payload;
      return { ...state, orderStatus: data, orderLoading: false };
    },
    getProductsCount(state, _action) {
      return { ...state, orderLoading: true };
    },
    setProductsCount(state, action) {
      const data = action.payload;
      return { ...state, count: data, orderLoading: false };
    },
    addToCart(state, _action) {
      return { ...state, orderLoading: true };
    },
    getShoppingCartSubscription(state, _action) {
      return { ...state, subscriptionLoading: true };
    },
    setShoppingCardSubscription(state, action) {
      return {
        ...state,
        isSubscribed: action.payload,
        subscriptionLoading: false,
      };
    },
    matchCountSync(state, _action) {
      return { ...state, orderLoading: true };
    },
  },
});

export const {
  getOrder,
  setOrder,
  createOrder,
  updateOrder,
  changeCurrency,
  itemQuantityChange,
  setOrderError,
  getOrderDetails,
  setOrderDetails,
  getOrderLegalTexts,
  setOrderLegalTexts,
  checkoutOrder,
  getOrderStatus,
  setOrderStatus,
  getProductsCount,
  setProductsCount,
  addToCart,
  getShoppingCartSubscription,
  setShoppingCardSubscription,
  matchCountSync,
} = orders.actions;

export default orders.reducer;
