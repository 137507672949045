/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { Loader } from '../Loader';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { WidgetInstance } from 'friendly-challenge';

const Captcha = (props: {
  onSolution: (data: string) => void;
  siteKey: string;
  start?: boolean;
  mappedErrors?: any;
  setMappedErrors?: React.Dispatch<any>;
}) => {
  const captchaWidgetRef = useRef<HTMLDivElement>(null);
  const widget = useRef<WidgetInstance | null>(null);
  const { language } = useContext(LanguageContext);

  const { onSolution: setSolution, siteKey, start = true, mappedErrors, setMappedErrors } = props;

  useEffect(() => {
    const el = captchaWidgetRef.current;
    if (el && start) {
      widget.current = new WidgetInstance(el, {
        doneCallback: (solution) => {
          setSolution(solution);
          setMappedErrors?.({ ...mappedErrors, captchaSolution: null });
        },
        errorCallback: (solution) => {
          console.log(solution);
        },
      });
    }
    if (start && widget.current?.start) {
      widget.current.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSolution, start, siteKey]);

  useEffect(() => {
    if (mappedErrors?.captchaSolution) {
      widget.current && widget.current.reset();
    }
  }, [mappedErrors]);

  if (!siteKey) return <Loader />;

  return (
    <>
      {mappedErrors?.['captchaSolution'] && (
        <Typography sx={{ color: 'red' }}>{mappedErrors['captchaSolution']?.messagesByLanguage?.[language]}</Typography>
      )}
      <div className="frc-captcha" ref={captchaWidgetRef} data-sitekey={siteKey} data-start="none"></div>
    </>
  );
};

export default Captcha;

