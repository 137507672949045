import React, { useState } from 'react';
import { FAQObjectType } from '../../../types/FAQ';

const faqObj = [
  {
    id: 0,
    h3: 'Question 1',
    p1: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias iusto sint fugit veniam et, cupiditate rem mollitia minus nemo nostrum. Porro assumenda vero vitae neque cum fugit amet et qui.',
    a1: 'quench your thirst for knowledge',
    a2: 'get your training now',
  },
  {
    id: 1,
    h3: 'Question 2',
    p1: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias iusto sint fugit veniam et, cupiditate rem mollitia minus nemo nostrum. Porro assumenda vero vitae neque cum fugit amet et qui.',
    a1: 'quench your thirst for knowledge',
    a2: 'get your training now',
  },
  {
    id: 2,
    h3: 'Question 3',
    p1: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Alias iusto sint fugit veniam et, cupiditate rem mollitia minus nemo nostrum. Porro assumenda vero vitae neque cum fugit amet et qui.',
    a1: 'quench your thirst for knowledge',
    a2: 'get your training now',
  },
];

const FAQ = () => {
  const transformedObj: FAQObjectType = { [0]: false };

  faqObj.forEach((el) => (transformedObj[el.id] = false));
  
  const [showFaq, setshowFaq] = useState<{ [key: number]: boolean }>(transformedObj);

  return (
    <section>
      {faqObj.map((el, id) => {
        return (
          <React.Fragment key={el.h3}>
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                if (Object.values(showFaq).every((el) => el === false)) {
                  setshowFaq({ ...transformedObj, [id]: true });
                  return;
                }
                if (showFaq[id] === true) {
                  setshowFaq({ ...transformedObj, [id]: !true });
                  return;
                }
                setshowFaq({ ...transformedObj, [id]: true });
              }}
            >
              <h3>{el.h3}</h3>
            </a>
            {showFaq[id] && (
              <>
                <p>{el.p1}</p>
                <div>
                  <a href="#cyber-security-training" className="central-anchor product-details__list--anchor">
                    {el.a1}
                    <br />
                    {el.a2}
                  </a>
                </div>
              </>
            )}
          </React.Fragment>
        );
      })}
    </section>
  );
};

export default FAQ;

