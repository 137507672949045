import { all, call, put } from 'redux-saga/effects';
import { requestGetInvoiceInfo, requestUpdateInvoiceInfo } from '../requests/invoice';
import { setInvoiceError, setInvoiceInfo, setStep } from '../../entities/invoice';
import { Invoice } from '../../../types/Invoice';
import { setDefaultInvoiceAddress } from '../../../lib/invoiceInfoMapper';

export function* handleGetInvoiceInfo(action: { payload: string }): Generator<object, void, Invoice> {
  try {
    const response = yield call(requestGetInvoiceInfo, action.payload);
    if (!response.invoiceAddress) {
      response.invoiceAddress = setDefaultInvoiceAddress(response.address);
    }
    yield put(setInvoiceInfo({ ...response }));
  } catch (error) {
    yield put(setInvoiceError(error));
  }
}

export function* handleUpdateInvoiceInfo(action: {
  payload: { orderId: string; data: Invoice; step: number };
}): Generator<object, void, { data: object }> {
  try {
    const response = yield call(requestUpdateInvoiceInfo, action.payload);
    yield all([yield put(setStep(action.payload.step + 1)), yield put(setInvoiceInfo({ ...response }))]);
  } catch (error) {
    yield put(setInvoiceError(error));
  }
}
