import { useState, useCallback } from 'react';
import useHttp from './useHttp';

interface Country {
  name: string;
  code: string;
  phoneCode: string;
}

const useCountry = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [myCountry, setMyCountry] = useState<Country>({
    code: '',
    name: '',
    phoneCode: '',
  });

  useHttp(
    { url: '/country/' },
    useCallback((data) => {
      setCountries(data);
    }, []),
  );

  useHttp(
    { url: '/geolocation/whereami/country' },
    useCallback((data) => {
      setMyCountry(data);
    }, []),
  );
  return { countries, myCountry };
};

export default useCountry;
