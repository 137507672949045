import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { addToCart, createOrder } from '../../../../store/entities/order';
import { CookieType } from '../../../../types/Cookie';
import { localization } from '../../../../assets/Localization';
import { getCookie } from '../../../../lib/cookieUtils';
import { IPaymentCurrencySwitch, PaymentFrequency } from '../../../../types/PaymentFrequency';
interface AddToCartButtonProps {
  productCode: string;
  quantity: number;
  currency: string;
  className?: string;
  text?: string;
  paymentFrequency?: string | null;
}

const AddToCart: React.FC<AddToCartButtonProps> = ({
  productCode,
  quantity,
  paymentFrequency,
  className,
  text,
}) => {
  const dispatch = useDispatch();
  const { language } = React.useContext(LanguageContext);

  const paymentCurrencySwitch = useSelector((state: IPaymentCurrencySwitch) => state.paymentCurrencySwitch);

  let paymentRegularity: PaymentFrequency | null = paymentCurrencySwitch.paymentFrequency;

  if (paymentFrequency === null) paymentRegularity = paymentFrequency;

  const addProductToCart = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (!productCode) return;
    const orderId = getCookie(CookieType.SHOPPING_CART);
    if (!orderId) {
      dispatch(
        createOrder({
          productCode,
          quantity,
          currency: paymentCurrencySwitch.currency,
          language,
          paymentFrequency: paymentRegularity,
        }),
      );
    } else {
      dispatch(
        addToCart({
          orderId,
          productCode,
          quantity,
          currency: paymentCurrencySwitch.currency,
          language,
          paymentFrequency: paymentRegularity,
        }),
      );
    }
  };

  return (
    <div>
      <a href="#" onClick={addProductToCart} className={className}>
        {text || localization[language].addToCart}
      </a>
    </div>
  );
};

export default AddToCart;

