import { createTheme, colors } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue[500],
    },
    secondary: {
      main: colors.red[300],
    },
  },
  typography: {
    fontFamily: 'notoSans',
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'notoSans';
          src: 
            local('notoSans Bold'), 
            local('NotoSans-Bold'), 
            url('/assets/fonts/notosans-bold.woff2') format('woff2'),
            url('/assets/fonts/notosans-bold.woff') format('woff');
          font-weight: bold;
          font-style: normal;
        }
        @font-face {
          font-family: 'notoSans';
          src: 
            local('notoSans Regular'), 
            local('NotoSans-Regular'), 
            url('/assets/fonts/notosans-regular.woff2') format('woff2'),
            url('/assets/fonts/notosans-regular.woff') format('woff');
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
  },
});

theme.typography.h2 = {
  fontSize: '1.5rem',
};

export default theme;
