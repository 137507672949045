import React from 'react';
import { Provider } from 'react-redux';
import { LanguageProvider } from './providers/LanguageProvider';
import { fetchXSRFToken } from './services/CSRFService';
import store from './store';
import { CookieType } from './types/Cookie';
import { getCookie } from './lib/cookieUtils';

const AppWeb: React.FC<{ children: React.ReactElement | React.ReactElement[] }> = ({ children }) => {
  React.useEffect(() => {
    if (!getCookie(CookieType.XSRF_TOKEN)) {
      fetchXSRFToken();
    }
  }, []);

  return (
    <Provider store={store}>
      <LanguageProvider>
        {children}
      </LanguageProvider>
    </Provider>
  );
};

export default AppWeb;
