/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiService } from '../services/ApiService';
import Http from '../types/Http';

const useHttp = (configObj: Http, configFn: (data: any) => void) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    setIsLoading(true);
    setError(null);

    const makeRequest = async () => {
      try {
        const axiosURL: string = configObj.url;
        const axiosConfig: AxiosRequestConfig = {
          method: configObj.method || 'GET',
          data: configObj.body,
          headers: configObj.headers as any,
          cancelToken: source.token,
        };

        const response = await ApiService(axiosURL, axiosConfig);
        configFn(response);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    makeRequest();

    return () => {};
  }, [configFn, configObj.body, configObj.headers, configObj.method, configObj.url]);

  return { error, isLoading };
};

export default useHttp;

