import { localization } from '../assets/Localization';
import { Currency, OrderItemUnit } from '../types/Order';
import { formatCurrency } from './formattingPrice';

export const formatQuantityText = (
  quantity: number,
  unitPrice: number,
  currency: Currency,
  language: string,
  unit: OrderItemUnit,
  periodMonths?: number,
): string => {
  const pluralize = (singular: string, plural: string, count: number): string => (count > 1 ? plural : singular);
  const seatText = unit
    ? pluralize(localization[language][unit.toLowerCase()], localization[language][`${unit.toLowerCase()}s`], quantity)
    : '';
  const formattedUnitPrice = formatCurrency(unitPrice, currency, false);

  if (periodMonths) {
    const monthText = pluralize(localization[language].month, localization[language].months, periodMonths);
    const perMonthPerSeatText = localization[language].perMonthPerSeat;
    return `(${quantity} ${seatText}) x (${formattedUnitPrice} ${currency} ${perMonthPerSeatText}) x (${periodMonths} ${monthText})`;
  }
  return `${quantity}x (${formattedUnitPrice} ${currency})`;
};

export const formatQuantityTextSimple = (quantity: number, language: string, periodMonths?: number): string => {
  if (periodMonths) {
    const monthText = periodMonths > 1 ? localization[language].months : localization[language].month;
    return `x ${quantity} (${periodMonths} ${monthText})`;
  }
  return `x ${quantity}`;
};
