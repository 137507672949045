const shoppingCart = {
  en: {
    title: 'Shopping Cart',
    product: 'Product',
    shortDescription: 'Description',
    moreDetails: 'More details',
    goToStorePage: 'Go to store page',
    currency: 'Currency',
    checkout: 'Continue to Checkout',
    total: 'Total',
    revert: 'Revert change',
    toCart: 'Go to Cart',
    emptyCart: 'Your cart is currently empty!',
    addToCart: 'Add to cart',
    changeShoppingCart: 'Change Shopping Cart',
    saveShoppingCart: 'Lost your shopping cart?',
    saveShoppingCartButton: 'save shopping cart',
    cyberSecurityGuide: 'Cybersecurity Education Guide',
    syncWithTrainings: 'Sync with trainings',
    shoppingCartNotFound: 'This shopping cart does no longer exists',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    iActAsBusiness: ' I am authorized to enter into this agreement on behalf of the above-named company. I am acting as a business, not as a consumer.',
  },
  de: {
    title: 'Einkaufswagen',
    product: 'Produkt',
    shortDescription: 'Beschreibung',
    moreDetails: 'Weitere Details',
    goToStorePage: 'Produkt im Shop anzeigen',
    currency: 'Währung',
    checkout: 'Weiter zur Kasse',
    total: 'Total',
    revert: 'Wiederherstellen',
    toCart: 'Zum Einkaufswagen',
    emptyCart: 'Dein Einkaufswagen ist derzeit leer!',
    addToCart: 'In den Einkaufswagen',
    changeShoppingCart: 'Einkaufswagen ändern',
    saveShoppingCartButton: 'Einkaufswagen speichern',
    saveShoppingCart: 'Einkaufswagen verloren?',
    cyberSecurityGuide: 'Leitfaden für Cybersecurity Ausbildung',
    syncWithTrainings: 'Mit Trainings synchronisieren',
    shoppingCartNotFound: 'Dieser Einkaufswagen existiert nicht mehr',
    termsOfUse: 'AGB',
    privacyPolicy: 'Datenschutzerklärung',
    and: 'und',
    iActAsBusiness: 'Ich bin berechtigt im Namen des oben genannten Unternehmens diesen Vertrag abzuschliessen. Ich handle als Unternehmen, nicht als Verbraucher.',
  },
};

export default shoppingCart;
