export type LanguageContextType = {
  language: string;
  supportedLanguages: string[];
  setLanguage: (language: string) => void;
};

export const Languages = {
  EN: 'en-US',
  DE: 'de-CH',
};

export type LanguageType = typeof Languages[keyof typeof Languages];
