import React from 'react';
import { LanguageContextType, Languages } from '../types/LanguageContext';
import { getCookie, setCookie } from '../lib/cookieUtils';
import { dateFormatting } from '../lib/dateFormatting';

export const LanguageContext = React.createContext<LanguageContextType>({
  language: Languages.EN,
  supportedLanguages: [Languages.EN, Languages.DE],
  setLanguage: () => {},
});

export const LOCALE_COOKIE_NAME = 'locale';

export const LanguageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const supportedLanguages = [Languages.EN, Languages.DE];
  const webLanguage = document.querySelector('html')?.getAttribute('lang');
  const [language, setLanguage] = React.useState((webLanguage && supportedLanguages.includes(webLanguage) ? webLanguage : undefined) || getCookie(LOCALE_COOKIE_NAME) || Languages.EN);

  const value: LanguageContextType = {
    language,
    supportedLanguages,
    setLanguage: (newLanguage) => {
      setCookie(LOCALE_COOKIE_NAME, newLanguage, dateFormatting(1, 'years'), '/');
      setLanguage(newLanguage);
    },
  };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
