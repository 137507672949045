import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import './index.css';
import App from './App';
import theme from './theme/theme';
import reportWebVitals from './reportWebVitals';
import AppWeb from './AppWeb';
import DrawerShoppingCartWeb from './components/pages/ShoppingCart/Drawer/DrawerShoppingCartWeb';
import AddToCart from './components/pages/ShoppingCart/AddToCart';
import DownloadableContentButton from './components/content/Downloadable/DownloadableContentButton';
import Questionnaire from './components/content/Questionnaire';
import FAQ from './components/pages/Products/FAQ';
import CurrencySelect from './components/pages/Products/CurrencySelect';
import ProductTableSwitch from './components/pages/Products/ProductTableSwitch';
import NewsletterInfoRedirect from './components/pages/Newsletter/NewsletterInfoRedirect';
import WebRoot, { SELECTED_CURRENCY_ATTRIBUTE } from './components/pages/WebRoot';
import Newsletter from './components/pages/Newsletter';
import PaymentFrequencies from './components/pages/Products/PaymentFrequencies';
import { PaymentFrequency } from './types/PaymentFrequency';
import { LicenseInfo } from '@mui/x-license-pro';

const renderRoot = (selector: string, nodeSupplier: (element: HTMLElement) => React.ReactNode): void => {
  const elementList = document.querySelectorAll(selector);

  for (let i = 0; i < elementList.length; i++) {
    const element = elementList.item(i) as HTMLElement;
    const root = ReactDOM.createRoot(element);

    root.render(nodeSupplier(element));
  }
};

LicenseInfo.setLicenseKey(
  'ff19de422dd2c9ea628ab0cfba55df9dTz03MTQzMSxFPTE3MjE5Mjc0OTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

renderRoot('#root', () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
));

renderRoot('.drawer-shopping-cart', () => (
  <AppWeb>
    <DrawerShoppingCartWeb />
  </AppWeb>
));

renderRoot('.add-to-cart-button', (element) => {
  const productCode = element.getAttribute('data-product-code') || '';
  const quantity = element.getAttribute('data-quantity') || 1;
  const currency = element.getAttribute('data-currency') || 'EUR';
  const text = element.getAttribute('data-text') || '';
  const paymentFrequency = element.getAttribute('data-payment-frequency');

  return (
    <AppWeb>
      <AddToCart
        productCode={productCode}
        quantity={+quantity}
        currency={currency}
        text={text}
        paymentFrequency={paymentFrequency}
      />
    </AppWeb>
  );
});

renderRoot('.downloadable-content-button', (element) => (
  <DownloadableContentButton code={element.getAttribute('data-downloadable-code')!} />
));

renderRoot('.web-root', () => (
  <AppWeb>
    <ThemeProvider theme={theme}>
      <WebRoot />
    </ThemeProvider>
  </AppWeb>
));

renderRoot('.newsletter-info-redirect', (element) => (
  <NewsletterInfoRedirect token={element.getAttribute('data-token')!} />
));

renderRoot('.questionnaire', () => (
  <AppWeb>
    <Questionnaire />
  </AppWeb>
));

renderRoot('.faq-component', () => <FAQ />);

renderRoot('.currency-select', (element) => (
  <AppWeb>
    <CurrencySelect
      selectedCurrency={element.getAttribute(SELECTED_CURRENCY_ATTRIBUTE)!}
      paymentFrequency={element.getAttribute('data-payment-frequency') as PaymentFrequency}
    />
  </AppWeb>
));

renderRoot('.product-table-switch', () => <ProductTableSwitch />);

renderRoot('.payment-frequency', (element) => (
  <AppWeb>
    <PaymentFrequencies
      paymentFrequency={element.getAttribute('data-payment-frequency') as PaymentFrequency}
      currency={element.getAttribute('data-currency')}
    />
  </AppWeb>
));

renderRoot('.subscribe-to-newsletter-button', () => (
  <AppWeb>
    <Newsletter />
  </AppWeb>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

