import { createSlice } from '@reduxjs/toolkit';
import { PaymentFrequency } from '../../types/PaymentFrequency';

const initialState = {
  paymentFrequency: PaymentFrequency.MONTHLY,
  currency: 'EUR',
};

const paymentCurrencySwitch = createSlice({
  name: 'paymentCurrencySwitch',
  initialState: initialState,
  reducers: {
    setPaymentCurrencySwitch(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setPaymentCurrencySwitch } = paymentCurrencySwitch.actions;

export default paymentCurrencySwitch.reducer;

