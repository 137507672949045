/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { getShoppingCartSubscription } from '../../../store/entities/order';
import { useSubscription } from '../../../hooks/useSubscription';
import { OrderState } from '../../../types/Order';
import { CookieType } from '../../../types/Cookie';
import Form from '../../common/Form';
import CustomModal from '../../common/CustomModal';
import { getCookie } from '../../../lib/cookieUtils';
import { localization } from '../../../assets/Localization';

let i = 0;
const ToggleExitPopup = () => {
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const [time, setTime] = useState(false);
  const shoppingCartId = getCookie(CookieType.SHOPPING_CART);
  const { count: shoppingCartLength, isSubscribed } = useSelector((state: OrderState) => state.orders);
  const { sendData, confirmationMessage } = useSubscription();
  const { language } = React.useContext(LanguageContext);

  useEffect(() => {
    if (shoppingCartId) dispatch(getShoppingCartSubscription(shoppingCartId));
  }, [dispatch, shoppingCartId]);

  const closeModal = () => {
    setToggleModal(false);
    sessionStorage.setItem('last_exit_intent', Date.now() + '');
  };

  let exitPopUpTime: NodeJS.Timeout | undefined;

  if (sessionStorage.getItem('last_exit_intent')) {
    exitPopUpTime = setTimeout(() => {
      setTime(true);
      sessionStorage.removeItem('last_exit_intent');
    }, 5 * 60 * 1000);
  }

  useEffect(() => {
    let initialDelayForPopUp: NodeJS.Timeout | undefined;
    let lastScroll = 0;
    let scrolledDownByHalf = 0;
    let scrolledUpByTen = 0;
    const openExitPopUpHandler = (event: Event) => {
      if (event.type !== 'scroll') {
        if (i === 0) {
          initialDelayForPopUp = setTimeout(() => {
            i++;
          }, 15000);
          return;
        }
        setToggleModal(true);
        return;
      }

      const scrollStart = document.documentElement.scrollTop;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollStart > 0 && lastScroll <= scrollStart) {
        lastScroll = scrollStart;
        if (lastScroll / pageHeight > 0.5) {
          scrolledDownByHalf = 1;
        }
      } else {
        lastScroll = scrollStart;
        if (lastScroll / pageHeight > 0.3 && lastScroll / pageHeight < 0.4 && scrolledDownByHalf) {
          scrolledUpByTen = 1;
        }
      }
      if (scrolledDownByHalf && scrolledUpByTen) setToggleModal(true);
    };

    if (!sessionStorage.getItem('last_exit_intent')) {
      document.documentElement.addEventListener('mouseleave', openExitPopUpHandler);
      document.addEventListener('scroll', openExitPopUpHandler);
      setTime(false);
    }

    return () => {
      clearTimeout(initialDelayForPopUp);
      document.documentElement.removeEventListener('mouseleave', openExitPopUpHandler);
      document.removeEventListener('scroll', openExitPopUpHandler);
      clearTimeout(exitPopUpTime);
    };
  }, [exitPopUpTime, time]);

  const renderModalContent = (contentData: {
    url: string;
    text: string;
    additional: string;
    img: ReactElement<any, any>;
    title?: string;
  }) => {
    return (
      <Form
        onSendingData={(data) => {
          sendData(contentData.url, data, () => {
            if (shoppingCartId) dispatch(getShoppingCartSubscription(shoppingCartId));
          });
        }}
        additional={contentData.additional}
        text={contentData.text}
        title={contentData.title || ''}
        img={contentData.img}
        confirmationMessage={confirmationMessage}
      />
    );
  };

  return (
    <>
      {!shoppingCartId && toggleModal ? (
        <CustomModal
          open={toggleModal}
          content={renderModalContent({
            url: `/downloadables/by-code/guide/subscriptions/`,
            text: localization[language].exitPopUpTextEmptyCart,
            title: localization[language].cyberSecurityGuide,
            additional: 'exit-popup',
            img: <div className="downloadable-content__img"></div>,
          })}
          onClose={closeModal}
        />
      ) : null}

      {shoppingCartId && shoppingCartLength > 0 && !isSubscribed && toggleModal ? (
        <CustomModal
          open={toggleModal}
          content={renderModalContent({
            url: `/orders/${shoppingCartId}/subscribe`,
            text: localization[language].exitPopUpTextSaveCart,
            title: localization[language].saveShoppingCart,
            additional: 'shopping-cart',
            img: <div className="shopping-cart__img"></div>,
          })}
          onClose={closeModal}
        />
      ) : null}
    </>
  );
};

export default ToggleExitPopup;
