import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detail: {},
  currency: '',
  received: '',
  contractAccepted: '',
  availableFrom: '',
  canceledBy: '',
  terminatedBy: '',
  terminatedFrom: '',
  refund: false,
  refundAmount: 0,
  refunded: false,
  refundedDate: '',
  payments: [],
  actions: [],
  totalAmountPaid: 0,
};

const orderDetailsBackOffice = createSlice({
  name: 'orderDetailsBackOffice',
  initialState: initialState,
  reducers: {
    getOrderDetailsBackOffice(state, _action) {
      return { ...state };
    },
    changeOrderStatus(state, _action) {
      return { ...state };
    },
    setOrderDetailsBackOffice(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearOrderDetailsBackOffice(_state, _action) {
      return {
        ...initialState,
      };
    },
  },
});

export const { getOrderDetailsBackOffice, setOrderDetailsBackOffice, changeOrderStatus, clearOrderDetailsBackOffice } =
  orderDetailsBackOffice.actions;

export default orderDetailsBackOffice.reducer;
