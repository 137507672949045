import React, { memo } from 'react';
import { Typography, Box, Button } from '@mui/material';
import classes from './ContinueButton.module.css';

interface ContinueButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  styles?: {
    root?: object;
    button?: object;
    textContainer?: object;
    iconContainer?: object;
  };
}

const ContinueButton: React.FC<ContinueButtonProps> = ({ text, onClick, styles = {}, disabled, ...restProps }) => {
  return (
    <Box className={classes['button-wrapper']} style={styles.root ? { ...styles.root } : {}}>
      <Button
        className={classes['button']}
        style={styles.button ? { ...styles.button } : {}}
        onClick={onClick}
        disabled={disabled}
        disableRipple
        {...restProps}
      >
        <Box className={`${classes['button-text']}`} style={styles.textContainer ? { ...styles.textContainer } : {}}>
          <Typography className={classes['text']}>{text}</Typography>
        </Box>
        <Box className={classes['button-arrow']} style={styles.iconContainer ? { ...styles.iconContainer } : {}} />
      </Button>
    </Box>
  );
};

export default memo(ContinueButton);
