import * as React from 'react';
import { RouterSuffix } from '../../../../types/RouterSuffix';
import { CookieType } from '../../../../types/Cookie';
import DrawerShoppingCart from './index';
import { getCookie } from '../../../../lib/cookieUtils';

const DrawerShoppingCartWeb: React.FC = () => {
  const goToCheckout = () => {
    const shoppingCartId = getCookie(CookieType.SHOPPING_CART);
    if (!shoppingCartId) return;
    window.location.href = `/${RouterSuffix.APP}/orders/${shoppingCartId}/shopping-cart`;
  };

  return <DrawerShoppingCart goToCheckout={goToCheckout} />;
};

export default DrawerShoppingCartWeb;
