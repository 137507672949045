import React, { useContext } from 'react';
import { localization } from '../../../assets/Localization';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { Typography } from '@mui/material';

const PhoneText = (props: { additional: string }) => {
  const { language } = useContext(LanguageContext);

  if (props.additional === 'contact')
    return (
      <Typography className='newsletter-from__phone--selection__text'>
        {localization[language].phoneTextContact}
      </Typography>
    );
    
  return (
    <Typography className='newsletter-from__phone--selection__text'>
      {localization[language].phoneText}
    </Typography>
  );
};

export default PhoneText;

