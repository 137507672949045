import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { localization } from '../../../assets/Localization';

const style = {
  '& .MuiButton-root:hover': {
    backgroundColor: 'unset',
    color: 'unset',
  },
  '& .MuiButton-root': {
    color: 'unset',
  },
};

const FormButton: React.FC<{ solution: string; termsIsChecked: boolean; additional?: string }> = ({
  solution,
  termsIsChecked,
  additional,
}) => {
  const { language } = useContext(LanguageContext);
  let buttonText = localization[language].submit;

  if (additional === 'contact') buttonText = localization[language].sendMessage;
  if (additional === 'sign-in') buttonText = localization[language].authForm;
  if (additional === 'exit-popup') buttonText = localization[language].downloadFree;
  if (additional === 'shopping-cart') buttonText = localization[language].saveShoppingCartButton;

  return (
    <Box className="newsletter-form__btn" sx={style}>
      <Button type="submit" disabled={solution && termsIsChecked ? false : true}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default FormButton;
