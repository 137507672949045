import React, { useContext, useState } from 'react';
import { ApiService } from '../services/ApiService';
import { LanguageContext } from './LanguageProvider';
import { Loader } from '../components/common/Loader';

export const AuthContext = React.createContext({
  isLoggedIn: false,
  setLoginData: (_success: boolean, _message?: Record<string, string>) => {},
  errorMessage: '',
  logout: () => {},
});

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { language } = useContext(LanguageContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchUser = React.useCallback(async () => {
    try {
      if (!window.location.href.includes('admin-panel')) return;
      await ApiService.get('/users/me');
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
      if (typeof error === 'object' && error !== null && 'message' in error) {
        setErrorMessage((error as { message: Record<string, string> }).message[language]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [language]);

  React.useEffect(() => {
    let isMounted = true;
    fetchUser().catch((error) => {
      if (isMounted) {
        console.error('Failed to fetch user:', error);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [fetchUser]);

  const handleLogin = (success: boolean, message?: Record<string, string>) => {
    if (success) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      if (message) {
        setErrorMessage(message[language]);
      }
    }
  };

  const logout = async () => {
    try {
      await ApiService.post('/users/logout');
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const value = {
    isLoggedIn,
    setLoginData: handleLogin,
    logout: logout,
    errorMessage: errorMessage,
  };

  if (isLoading) {
    return <Loader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
