import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { mapErrorResponse } from '../../../lib/mapErrorResponse';
import { ErrorType } from '../../../types/Errors';

type InputTextProps = {
  title: string;
  type: string;
  isRequired: boolean;
  id?: string;
  name?: string;
  defaultValue?: string;
  className?: string;
  value?: string;
  errors?: ErrorType;
  pattern?: string;
};

const style = {
  '& .MuiInputBase-input': {
    border: 'unset',
  },
};

const InputText = React.forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const { language } = useContext(LanguageContext);
  const [mappedErrors, setMappedErrors] = useState(mapErrorResponse(props.errors || ({} as ErrorType)));

  useEffect(() => {
    props.errors && setMappedErrors(mapErrorResponse(props.errors));
  }, [props.errors]);

  return (
    <Box className={props.className ?? 'newsletter-form__name'}>
      {props.name && (
        <Typography sx={{ color: 'red' }}>{mappedErrors[props.name]?.messagesByLanguage?.[language]}</Typography>
      )}
      <TextField
        variant="standard"
        name={props.name}
        label={props.title}
        type={props.type}
        id={props.id}
        inputRef={ref}
        required={props.isRequired}
        defaultValue={props.defaultValue}
        value={props.value}
        sx={style}
        inputProps={{ pattern: props.pattern }}
        error={!!props.name && !!mappedErrors?.[props.name]}
      />
    </Box>
  );
});

InputText.displayName = 'InputText';

export default InputText;

