import React from 'react';

import TableConfigParams from '../../../types/TableConfigParams';

const Table = (props: { tableData: { params: TableConfigParams }; onClick: () => void }) => {
  return (
    <div className='webinar-table'>
      <table className='webinars__registration-table'>
        <thead>
          <tr>
            <th>{props.tableData.params.title}</th>
            <th>{props.tableData.params.date}</th>
            <th>{props.tableData.params.time}</th>
            <th>{props.tableData.params.duration}</th>
            <th>{props.tableData.params.action}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a onClick={props.onClick}>{props.tableData.params.content.title}</a>
            </td>
            <td>{props.tableData.params.content.date}</td>
            <td>{props.tableData.params.content.time}</td>
            <td>{props.tableData.params.content.duration}</td>
            <td>
              <a onClick={props.onClick}>{props.tableData.params.content.action}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;

