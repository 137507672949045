import { Box, Grid, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import useCountry from '../../../hooks/useCountries';
import { localization } from '../../../assets/Localization';
import { LanguageContext } from '../../../providers/LanguageProvider';
import classes from '../../pages/CheckoutProcess/CheckoutProcess.module.css';
import Country from '../../../types/Country';

const style = {
  '& .MuiFormLabel-root': {
    fontSize: '1.3rem',
  },
  '& .MuiInput-input': {
    border: 'unset',
  },
};

const CountryPhoneForm = React.forwardRef<
  HTMLInputElement,
  {
    selectCountry: React.Dispatch<React.SetStateAction<string>>;
    phoneErrors: React.Dispatch<
      React.SetStateAction<{
        [key: string]: {
          phone: string;
        };
      }>
    >;
  }
>(({ selectCountry, phoneErrors }, ref) => {
  const { language } = useContext(LanguageContext);
  const { countries, myCountry } = useCountry();
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    selectCountry(myCountry.code);
  }, [selectCountry, myCountry.code]);

  const phoneInvalidHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const pattern = /^[0-9 -]+$/;

    if (pattern.test(value)) {
      return phoneErrors((prevValues) => {
        return {
          ['en-US']: { ...prevValues['en-US'], phone: '' },
          ['de-CH']: { ...prevValues['de-CH'], phone: '' },
        };
      });
    }

    phoneErrors((prevValues) => {
      return {
        ['en-US']: {
          ...prevValues['en-US'],
          phone:
            'Only use numbers, space, and minus. Please use the international phone number format with your selected country code.',
        },
        ['de-CH']: {
          ...prevValues['de-CH'],
          phone:
            'Verwenden Sie nur Zahlen, Leerzeichen und Minuszeichen. Bitte verwenden Sie das internationale Telefonnummernformat mit Ihrer gewählten Landesvorwahl.',
        },
      };
    });
  };

  const countryChangeHandler = (event: SelectChangeEvent<string>) => {
    selectCountry(event.target.value);
    setSelectedCountry(event.target.value);
  };

  return (
    <Grid item xs={12} style={{ paddingTop: 5 }}>
      <Box className={classes['contact-phone-container']}>
        <Select
          required
          labelId="countries"
          name="phoneCountryCode"
          variant="standard"
          style={{ marginRight: 5 }}
          value={countries.length ? selectedCountry || myCountry.code : ''}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
          onChange={countryChangeHandler}
        >
          {countries.map((country: Country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={style}
          label={localization[language].phone}
          onChange={phoneInvalidHandler}
          inputRef={ref}
          variant="standard"
          type="tel"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                (+{countries.find((el) => el.code === selectedCountry)?.phoneCode || myCountry.phoneCode})
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Grid>
  );
});

CountryPhoneForm.displayName = 'CountryPhoneForm';

export default CountryPhoneForm;

