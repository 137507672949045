import { createSlice } from '@reduxjs/toolkit';
import { SortDirection } from '../../types/AdminOrders';

const adminOrders = createSlice({
  name: 'adminOrders',
  initialState: {
    results: [{id: ''}],
    sortDirection: SortDirection.ASC,
    dateRange: {},
    fromDate: '',
    toDate: '',
    statuses: [],
    number: '',
    company: '',
    totalResults: 0,
    totalPages: 1,
    hasFilters: false,
  },
  reducers: {
    getData(state, _action) {
      return { ...state };
    },
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
        hasFilters: true,
      };
    },
    deleteData(state, action) {
      return {
        ...state,
        results: [...state.results.filter(el => el.id !== action.payload)]
      };
    },
    setFromDate(state, action) {
      return { ...state, fromDate: action.payload };
    },
    setToDate(state, action) {
      return { ...state, toDate: action.payload };
    },
    setStatuses(state, action) {
      return { ...state, statuses: action.payload };
    },
  },
});

export const { getData, setData, setFromDate, setStatuses, setToDate, deleteData } = adminOrders.actions;

export default adminOrders.reducer;

