import React, { Suspense, lazy, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { RouterSuffix } from '../../../types/RouterSuffix';
import { Loader } from '../../common';
import { RequireAuth } from './RequireAuth';
import { localization } from '../../../assets/Localization';

const ShoppingCart = lazy(() => import('../../pages/ShoppingCart'));
const RestoreShoppingCart = lazy(() => import('../../pages/ShoppingCart/RestoreShoppingCart'));
const Info = lazy(() => import('../../pages/Newsletter/Info'));
const Webinars = lazy(() => import('../../pages/Webinars'));
const Webinar = lazy(() => import('../../pages/Webinars/Webinar'));
const DownloadableContent = lazy(() => import('../../pages/DownloadableContent/DownloadableContent'));
const NotFound = lazy(() => import('../../pages/NotFound'));
const CheckoutProcess = lazy(() => import('../../pages/CheckoutProcess'));
const ViewOrder = lazy(() => import('../../pages/CheckoutProcess/ViewOrder'));
const Contact = lazy(() => import('../../pages/Contact'));
const AdminPanel = lazy(() => import('../../pages/AdminPanel'));
const ListOfOrders = lazy(() => import('../../pages/AdminPanel/ListOfOrders/ListOfOrders'));
const ListOfMessages = lazy(() => import('../../pages/AdminPanel/ListOfMessages'));
const ListOfWebinars = lazy(() => import('../../pages/AdminPanel/ListOfWebinars'));
const CreateWebinar = lazy(() => import('../../pages/AdminPanel/ListOfWebinars/CreateWebinar'));
const EditWebinar = lazy(() => import('../../pages/AdminPanel/ListOfWebinars/EditWebinar'));
const MessageDetail = lazy(() => import('../../pages/AdminPanel/ListOfMessages/MessageDetail'));
const OrderDetail = lazy(() => import('../../pages/AdminPanel/OrderDetail/OrderDetail'));
const AuthForm = lazy(() => import('../../pages/AdminPanel/Authentication/AuthForm'));

const AppRouter: React.FC = () => {
  const { language } = useContext(LanguageContext);

  return (
    <BrowserRouter basename={RouterSuffix.APP}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="orders/:id/restore-shopping-cart" element={<RestoreShoppingCart />} />
          <Route path="orders/:id/shopping-cart" element={<ShoppingCart />} />
          <Route path="/no-shopping-cart" element={<NotFound text={localization[language].shoppingCartNotFound} />} />
          <Route path="orders/:id/checkout" element={<CheckoutProcess />} />
          <Route path="orders/:id/tracking" element={<ViewOrder />} />
          <Route path="/newsletter/:token/" element={<Info />} />
          <Route path="/webinars" element={<Webinars />} />
          <Route path="/webinar/:id/:lang" element={<Webinar />} />
          <Route path="/downloadable-content/:id/:lang" element={<DownloadableContent />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/admin-panel/login" element={<AuthForm />} />
          <Route
            path="/admin-panel/home"
            element={
              <RequireAuth>
                <AdminPanel />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/orders"
            element={
              <RequireAuth>
                <ListOfOrders />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/orders/:id"
            element={
              <RequireAuth>
                <OrderDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/messages"
            element={
              <RequireAuth>
                <ListOfMessages />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/messages/:id"
            element={
              <RequireAuth>
                <MessageDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/webinars"
            element={
              <RequireAuth>
                <ListOfWebinars />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/webinars/:id"
            element={
              <RequireAuth>
                <EditWebinar />
              </RequireAuth>
            }
          />
          <Route
            path="/admin-panel/create-webinar"
            element={
              <RequireAuth>
                <CreateWebinar />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRouter;

