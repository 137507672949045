import axios from 'axios';
import { RouterSuffix } from '../types/RouterSuffix';
import { CookieType } from '../types/Cookie';
import { getCookie } from '../lib/cookieUtils';
import { fetchXSRFToken } from './CSRFService';

const defaultConfig = {
  baseURL: `/${RouterSuffix.API}`,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const ApiService = axios.create(defaultConfig);

// set up interceptor to handle error responses and return data payload
ApiService.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (err) => {
    console.log(err, 'this is interceptor err');
    if (
      err.response.status === 401 &&
      window.location.href.includes('admin-panel') &&
      !window.location.href.includes('/login')
    )
      window.location.href = '/app/admin-panel/login';
    if (err.response.data) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  },
);

axios.interceptors.request.use(
  async (config) => {
    if (config?.method && ['post', 'put', 'delete'].includes(config.method)) {
      let xsrfToken = getCookie(CookieType.XSRF_TOKEN);
      if (!xsrfToken) {
        const response = await fetchXSRFToken();
        if (response) xsrfToken = response;
      }
      config.headers['X-XSRF-Token'] = xsrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
