import React, { useContext, useEffect } from 'react';
import ToggleExitPopup from '../navigation/Footer/ToggleExitPopup';
import ToggleCookieBanner from '../navigation/Footer/ToggleCookieBanner';
import { LanguageContext, LOCALE_COOKIE_NAME } from '../../providers/LanguageProvider';
import { CookieType } from '../../types/Cookie';
import { getCookie, setCookie } from '../../lib/cookieUtils';
import { dateFormatting } from '../../lib/dateFormatting';

export const SELECTED_CURRENCY_ATTRIBUTE = 'data-selected-currency';

/**
 * This component handles setting of language and currency cookie in server-side rendered part of application using
 * Spring MVC.
 *
 * Since the server responses can utilize HTTP cache, we cannot rely on setting the cookies by the server.
 * Instead, we set them using JavaScript according to the language and currency data that are part of the HTML output.
 */
const WebRoot: React.FC = () => {
  const { supportedLanguages, setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const webLanguage = document.querySelector('html')?.getAttribute('lang');
    const cookieLanguage = getCookie(LOCALE_COOKIE_NAME);

    if (webLanguage && supportedLanguages.includes(webLanguage) && webLanguage !== cookieLanguage) {
      setLanguage(webLanguage);
    }

    const selectedCurrency = document
      .querySelector(`[${SELECTED_CURRENCY_ATTRIBUTE}]`)
      ?.getAttribute(SELECTED_CURRENCY_ATTRIBUTE);
    const cookieCurrency = getCookie(CookieType.CURRENCY);

    if (selectedCurrency && selectedCurrency !== cookieCurrency) {
      setCookie(CookieType.CURRENCY, selectedCurrency, dateFormatting(1, 'years'), '/');
    }
  }, [supportedLanguages, setLanguage]);

  return (
    <>
      <ToggleExitPopup />
      <ToggleCookieBanner />
    </>
  );
};

export default WebRoot;
