import React from 'react';
import { Provider } from 'react-redux';
import { LanguageProvider } from './providers/LanguageProvider';
import { ErrorProvider } from './providers/ErrorProvider';
import { AuthProvider } from './providers/AuthProvider';
import { fetchXSRFToken } from './services/CSRFService';
import store from './store';
import { CookieType } from './types/Cookie';
import AppRouter from './components/navigation/AppRouter';
import { getCookie } from './lib/cookieUtils';

const App: React.FC = () => {
  React.useEffect(() => {
    if (!getCookie(CookieType.XSRF_TOKEN)) {
      fetchXSRFToken();
    }
  }, []);

  return (
    <Provider store={store}>
      <ErrorProvider>
        <LanguageProvider>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </LanguageProvider>
      </ErrorProvider>
    </Provider>
  );
};

export default App;
