import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
  return (
    <div className="loader">
      <CircularProgress />
    </div>
  );
};
