/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import NewsletterInfoRadioButton from '../components/common/Form/NewsletterInfoForm/NewsletterInfoRadioButton';
import InputText from '../components/common/Form/InputText';
import { LanguageContext } from '../providers/LanguageProvider';
import { Gender, Recipient } from '../types/FormSendingData';
import { localization } from '../assets/Localization';

const useContactForm = ({
  additional,
  mappedErrors,
  setMappedErrors,
}: {
  additional?: string;
  mappedErrors?: any;
  setMappedErrors?: React.Dispatch<any>;
}) => {
  const { language } = useContext(LanguageContext);
  const [gender, setGender] = useState<Gender | undefined>();
  const [recipient, setRecipient] = useState(Recipient.MESSAGE_CENTRAL);

  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const companyInputRef = useRef<HTMLInputElement>(null);
  const subjectInputRef = useRef<HTMLInputElement>(null);

  const changeGenderHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(Gender[`${event.target.value as Gender}`]);
  };

  const changeRecipientHandler = (event: SelectChangeEvent<Recipient>) => {
    setRecipient(event?.target.value as Recipient);
  };

  let genderField = <></>;
  let companyField = <></>;
  let recipientField = <></>;
  let subjectField = <></>;
  let messageField = <></>;

  if (additional === 'contact') {
    genderField = (
      <NewsletterInfoRadioButton
        id="gender"
        enumObject={Gender}
        isChecked={gender}
        onChange={changeGenderHandler}
        localizationText="genderOptional"
        className="u-flex-row"
      />
    );

    companyField = (
      <InputText
        id="company"
        type="Company"
        isRequired={false}
        title={localization[language].company}
        ref={companyInputRef}
      />
    );

    recipientField = (
      <Box className="newsletter-form__select">
        <FormControl fullWidth>
          <InputLabel id="recipient">{localization[language].recipient}</InputLabel>
          <Select
            required
            labelId="recipient"
            variant="standard"
            style={{ marginRight: 5 }}
            value={recipient}
            inputProps={{ MenuProps: { disableScrollLock: true } }}
            onChange={changeRecipientHandler}
          >
            {Object.values(Recipient).map((el) => (
              <MenuItem key={el} value={el}>
                {localization[language][el]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );

    subjectField = (
      <InputText
        id="subject"
        type="Subject"
        isRequired={true}
        title={localization[language].subject}
        ref={subjectInputRef}
      />
    );

    messageField = (
      <>
        <Typography sx={{ color: 'red' }}>{mappedErrors['message']?.messagesByLanguage?.[language]}</Typography>
        <TextField
          inputRef={messageInputRef}
          className="contact-message"
          name="message"
          id="message"
          multiline={true}
          rows={5}
          label={localization[language].yourMessage}
          required
          inputProps={{
            minLength: 20,
          }}
          error={!!mappedErrors?.['message']}
          onBeforeInput={() => setMappedErrors?.({ ...mappedErrors, ['message']: false })}
        />
      </>
    );
  }
  return {
    messageField,
    genderField,
    companyField,
    subjectField,
    recipientField,
    message: messageInputRef.current?.value,
    gender,
    company: companyInputRef.current?.value,
    subject: subjectInputRef.current?.value,
    recipient,
  };
};

export default useContactForm;

