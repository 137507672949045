import { Address } from '../types/Invoice';

export const setDefaultInvoiceAddress = (defaultAddress: Address) => {
  const invoiceAddress = {
    street: '',
    additionalAddressLine: '',
    zip: '',
    city: '',
    state: '',
    country: defaultAddress.country,
    countryCode: defaultAddress.countryCode,
  };

  return invoiceAddress;
};
