import { useCallback, useState } from 'react';
import useHttp from './useHttp';

const useSiteKey = (url: string) => {
  const [siteKey, setSiteKey] = useState('');

  useHttp(
    { url: url },
    useCallback((data: string) => {
      setSiteKey(data);
    }, []),
  );

  return siteKey;
};

export default useSiteKey;
