const forms = {
  en: {
    newsletterAdditionalText:
      'We’ll keep you informed. To ensure you receive only the most relevant information, you may update your preferences.',
    subSettings: 'Subscription settings',
    successSave: 'You have successfully saved your preferences.',
    successUnsubscribe: 'You have successfully unsubscribed from the newsletter.',
    confirmSubmission: 'Confirm Subscription',
    confirmationMessage: 'Please click the button to confirm your subscription.',
    preferredLanguage: 'Preferred Language',
    role: 'Role',
    selectOption: 'select an option',
    EXECUTIVE: 'Executive',
    CISO: 'CISO',
    INFORMATION_SECURITY_OFFICER: 'Information Security Officer',
    HEAD_OF_IT: 'Head of IT',
    DEPARTMENT_TEAM_LEAD: 'Department Team Lead',
    CYBER_SECURITY_EXPERT: 'Cyber Security Expert',
    SOFTWARE_DEVELOPER: 'Software Developer',
    IT_OPERATOR: 'IT Operator',
    IT_ARCHITECT: 'IT Architect',
    ENTERPRISE_ARCHITECT: 'Enterprise Architect',
    HUMAN_RESOURCES: 'Human Resources',
    TRAINER: 'Trainer',
    FREELANCER: 'Freelancer',
    OTHER: 'Other',
    experienceLevel: 'Experience Level',
    none: 'None',
    beginner: 'Beginner',
    intermediate: 'Intermediate',
    expert: 'Expert',
    iWantTo: 'I Want To',
    save: 'Save',
    unsubscribe: 'Unsubscribe',
    message: 'Message',
    subscribeToNewsletter: 'Subscribe to our newsletter',
    subscribeMessage: 'You can subscribe to very useful information in the next step',
    webinarTitle: 'Webinar',
    webinarFormName: 'Sign up',
    webinarTitleHeading: 'Webinar',
    webinarDateHeading: 'Date',
    webinarTimeHeading: 'Time',
    webinarDurationHeading: 'Duration',
    webinarActionHeading: 'Action',
    webinarDateContent: 'Sep 1, 2023',
    webinarTimeContent: '3:00 PM',
    webinarFor: 'for',
    webinarAt: 'at',
    webinarDurationContent: '60 Minutes',
    phoneTextContact:
      '(optional) We are happy to answer all your questions by phone. Please enter your phone number when you want talk to an expert.',
    phoneText:
      'Please only provide a phone number if you want us to answer your questions in an 8 minutes phone call (optional)',
    submit: 'Submit',
    sendMessage: 'Send Message',
    newsletterTitle: 'Subscribe to our newsletter',
    contactTitle: 'Contact Us',
    generalSuccessMessage: 'You have successfully submitted the form.',
    contactSuccessMessage: 'You have successfully send us the message.',
    notFound: 'The page you are looking for does not exist.',
    exitPopUpTextEmptyCart:
      'Wait! You forgot your Cybersecurity Education Guide. Get it now for free!\nThis guide show you all the topics you need to learn for each role to be prepared for cyberattacks and compliance with new regulations.',
    exitPopUpTextSaveCart:
      'Wait! You forgot your shopping cart. You can save it now and return to it any time.\nYou can also forward the link to your boss or buyer to complete the order for you.',
    confirmationMessageEmptyCart:
      'To receive your download link, please click the confirmation link in the email we just sent you. This additional step protects your privacy. Thank you!',
    confirmationMessageSaveCart: 'You have successfully saved your cart!',
    authForm: 'Sign-In',
    company: 'Company (optional)',
    emailAddress: 'Email Address',
    recipient: 'Recipient',
    subject: 'Subject',
    yourMessage: 'Your Message',
    MESSAGE_CENTRAL: 'Message Central',
    EDUCATION_EXPERT: 'Education Expert',
    DATA_PRIVACY_OFFICER: 'Data Privacy Officer',
    INFORMATION_SECURITY_OFFICER_SECURITY_ISSUES_ONLY: 'Information Security Officer (security issues only)',
    areYouSureYouWantToArchive: 'Are you sure you want to archive the message?',
    archive: 'Archive',
    cancel: 'Cancel',
    markAsUnread: 'Mark as unread',
    read: 'Read',
    downloadFree: 'Download now (free)',
  },
  de: {
    newsletterAdditionalText:
      'Wir halten dich auf dem Laufenden. Damit du nur die relevantesten Informationen bekommst, kannst du deine Interessen angeben.',
    subSettings: 'Abonnementeinstellungen',
    successSave: 'Du hast deine Einstellungen erfolgreich gespeichert.',
    successUnsubscribe: 'Du hast dich erfolgreich vom Newsletter abgemeldet.',
    confirmSubmission: 'Anmeldung bestätigen',
    confirmationMessage: 'Bitte klicke auf den Link, um deine Anmeldung zu bestätigen.',
    preferredLanguage: 'Bevorzugte Sprache',
    role: 'Rolle',
    selectOption: 'wähle eine option',
    EXECUTIVE: 'Exekutive',
    CISO: 'CISO',
    INFORMATION_SECURITY_OFFICER: 'Informationssicherheitsbeauftragter',
    HEAD_OF_IT: 'Leiter IT',
    DEPARTMENT_TEAM_LEAD: 'Teamleiter der Abteilung',
    CYBER_SECURITY_EXPERT: 'Cyber-Sicherheitsexperte',
    SOFTWARE_DEVELOPER: 'Softwareentwickler',
    IT_OPERATOR: 'IT-Betreiber',
    IT_ARCHITECT: 'IT-Architekt',
    ENTERPRISE_ARCHITECT: 'Unternehmensarchitekt',
    HUMAN_RESOURCES: 'Humanressourcen',
    TRAINER: 'Trainer',
    FREELANCER: 'Freiberufler',
    OTHER: 'Andere',
    experienceLevel: 'Erfahrungsniveau',
    none: 'Keiner',
    beginner: 'Anfänger',
    intermediate: 'Dazwischenliegend',
    expert: 'Experte',
    iWantTo: 'Ich möchte',
    save: 'Speichern',
    unsubscribe: 'Abbestellen',
    message: 'Nachricht',
    subscribeToNewsletter: 'Abonniere unseren Newsletter',
    subscribeMessage: 'Im nächsten Schritt kannst du sehr nützliche Informationen abonnieren',
    webinarTitle: 'Webinar',
    webinarFormName: 'Anmeldung',
    webinarTitleHeading: 'Webinar',
    webinarDateHeading: 'Datum',
    webinarTimeHeading: 'Zeit',
    webinarDurationHeading: 'Dauer',
    webinarActionHeading: 'Aktion',
    webinarDateContent: '1. September 2023',
    webinarTimeContent: '15:00 Uhr',
    webinarFor: 'für',
    webinarAt: 'um',
    webinarDurationContent: '60 Minuten',
    phoneTextContact:
      '(optional) Gerne beantworten wir deine Fragen telefonisch. Wenn du mit einem Experte sprechen möchtest, gebe bitte deine Telefonnummer an.',
    phoneText:
      'Bitte gebe nur dann deine Telefonnummer an, wenn du möchtest, dass wir deine Fragen in einem 8-minütigen Telefonat beantworten (optional).',
    submit: 'Absenden',
    sendMessage: 'Nachricht senden',
    newsletterTitle: 'Abonniere unseren Newsletter',
    contactTitle: 'Kontaktiere uns',
    generalSuccessMessage: 'Du hast das Formular erfolgreich abgeschickt.',
    contactSuccessMessage: 'Du hast die Nachricht erfolgreich an uns gesendet',
    notFound: 'Die gesuchte Seite existiert nicht.',
    exitPopUpTextEmptyCart:
      'Warte! Du hast deinen Cybersecurity Leitfaden vergessen. Jetzt gratis herunterladen!\nDieser Leitfaden zeigt dir für jede Rolle alle Themen, die du lernen solltest, um auf Cyberangriffe und die Compliance mit den neuen Regularien vorbereitet zu sein.',
    exitPopUpTextSaveCart:
      'Warte! Du hast deinen Einkaufswagen vergessen. Du kannst ihn jetzt speichern und dann jederzeit wieder aufrufen.\nDu kannst den Link auch an deine Führungskraft oder Einkäufer weiterleiten, damit diese die Bestellung abschliessen.',
    confirmationMessageEmptyCart:
      'Um deinen Download Link zu erhalten, klicke bitte auf den Link in der E-Mail, die wir dir gerade geschickt haben. Dieser zusätzliche Schritt dient dem Schutz deiner Daten. Danke!',
    confirmationMessageSaveCart: 'Du hast deinen Einkaufswagen erfolgreich gespeichert!',
    authForm: 'Anmelden',
    company: 'Firma (optional)',
    emailAddress: 'E-Mail Adresse',
    recipient: 'Empfänger',
    subject: 'Betreff',
    yourMessage: 'Ihre Nachricht',
    MESSAGE_CENTRAL: 'Zentrale',
    EDUCATION_EXPERT: 'Weiterbildungsexperte',
    DATA_PRIVACY_OFFICER: 'Datenschutzbeauftragter',
    INFORMATION_SECURITY_OFFICER_SECURITY_ISSUES_ONLY: 'Informationssicherheit (nur Sicherheitsvorfälle)',
    areYouSureYouWantToArchive: 'Sind Sie sicher, dass Sie die Nachricht archivieren möchten?',
    archive: 'Archiv',
    cancel: 'Stornieren',
    markAsUnread: 'Als ungelesen markieren',
    read: 'Lesen',
    downloadFree: 'Jetzt herunterladen (gratis)',
  },
};

export default forms;

