import { OrderStatus, OrderDetails, Currency } from './Order';

export type OrdersResponseResults = {
  id: string;
  status: string;
  received?: string;
  totalExclVat?: number;
  totalInclVat?: number;
  currency?: string;
  number?: string;
  company?: string;
};

export type OrdersResponseType = {
  page: number;
  totalPages: number;
  totalResults: number;
  results: OrdersResponseResults[];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortColumn {
  STATUS = 'STATUS',
  RECEIVED = 'RECEIVED',
  COMPANY = 'COMPANY',
  TOTAL_EXCL_VAT = 'TOTAL_EXCL_VAT',
  NUMBER = 'NUMBER',
}

export type SVGType = { className?: string; onClick?: () => void };

export type GetDataType = {
  page?: number;
  itemsPerPage?: number;
  sortColumn?: string;
  sortDirection?: string;
  statuses?: string[];
  dateRange?: {
    fromInclusive?: string;
    toInclusive?: string;
  };
  company?: string;
  number?: string;
};

export type OrdersType = {
  results: OrdersResponseResults[];
  sortDirection: SortDirection;
  fromDate: string;
  toDate: string;
  statuses: string[];
  number: string;
  company: string;
  totalResults: number;
  totalPages: number;
  page: number;
  hasFilters: boolean;
};

export type adminOrdersType = {
  adminOrders: OrdersType;
};

export type StatusCheckboxType = {
  id: string;
  checked: boolean;
};

export enum Type {
  STATUS_CHANGE = 'STATUS_CHANGE',
  CUSTOM_ENDPOINT = 'CUSTOM_ENDPOINT',
}

export enum Action {
  CREATE = 'CREATE',
  BUY = 'BUY',
  CANCEL = 'CANCEL',
  ABANDON = 'ABANDON',
  REOPEN = 'REOPEN',
  ARCHIVE = 'ARCHIVE',
  START_WORK = 'START_WORK',
  INVOICE = 'INVOICE',
  PAY = 'PAY',
  CANCEL_REFUND = 'CANCEL_REFUND',
  COMPLETE = 'COMPLETE',
  START_TERMINATION = 'START_TERMINATION',
  TERMINATE = 'TERMINATE',
  ARCHIVE_REFUND = 'ARCHIVE_REFUND',
  RENEW = 'RENEW',
  DELETE = 'DELETE',
}

export enum CanceledBy {
  CUSTOMER = 'CUSTOMER',
  SALES = 'SALES',
  SYSTEM = 'SYSTEM',
  ADMIN = 'ADMIN',
}

export enum TerminatedBy {
  CUSTOMER = 'CUSTOMER',
  SALES = 'SALES',
  CONTRACT_LIMIT = 'CONTRACT_LIMIT',
  PRODUCT_END = 'PRODUCT_END',
}

export type OrderDetailType = {
  detail: OrderDetails;
  currency: Currency;
  received?: string;
  contractAccepted?: string;
  availableFrom?: string;
  canceledBy?: CanceledBy;
  terminatedBy?: TerminatedBy;
  terminatedFrom?: string;
  refund: boolean;
  refundAmount?: number;
  refunded: boolean;
  refundedDate?: string;
  payments: { valueDate: string; amount: number }[];
  actions: { action: Action; type: Type; newStatus: OrderStatus }[];
  totalAmountPaid: number;
};

export type AdminOrderDetailType = {
  orderDetailsBackOffice: OrderDetailType;
};

export type ChangeOrderStatusType = {
  id: string;
  action: string;
  amount?: number;
  availableFrom?: string;
  refund?: boolean;
  refundAmount?: number;
  refundDate?: string;
};

export enum Role {
  CUSTOMER = 'CUSTOMER',
  SALES = 'SALES',
  SYSTEM = 'SYSTEM',
  ADMIN = 'ADMIN',
}

export enum UserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SALES = 'ROLE_SALES',
}

export type UserInfo = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  roles: UserRole;
};

export type OrderEventChanges = {
  paymentAmount: number;
  paymentDate: string;
  refund: boolean;
  refundAmount: number;
  refundedDate: string;
  terminatedBy: TerminatedBy;
  created: string;
  canceledBy: CanceledBy;
  received: string;
  contractAccepted: string;
  refunded: boolean;
  availableFrom: string;
  terminatedFrom: string;
};

export type OrderHistoryType = {
  timestamp: string;
  action: Action;
  newStatus: OrderStatus;
  role: Role;
  userInfo: UserInfo;
  changes: Partial<OrderEventChanges>;
}[];

export enum FilterIdentifierType {
  STATUS = 'Status',
  DATE = 'Date',
  CUSTOMER = 'Customer',
  ORDER = 'Order',
}

export type FilterColumnToggleType = {
  toggleStatus: boolean;
  toggleDate: boolean;
  toggleCustomer: boolean;
  toggleOrder: boolean;
};
