import React, { useState } from 'react';
import AddToCart from '../../pages/ShoppingCart/AddToCart';
import { getCookie } from '../../../lib/cookieUtils';

const questions = [
  {
    question: 'Was ist deine Rolle',
    firstBoxText: ' Ich bin Manager',
    firstBoxAdditonal: '(CEO, CIO, IT-Leiter, CISO)',
    secondBoxText: ' Ich bin Teamleiter',
    secondBoxAdditonal: '(IT- oder Security Teamleiter)',
    thirdBoxText: 'Ich bin Freelancer',
    thirdBoxAdditonal: '(IT- oder Security Freelancer)',
  },
  {
    question: 'Second question',
    firstBoxText: 'First answer',
    firstBoxAdditonal: '',
    secondBoxText: ' Second answer',
    secondBoxAdditonal: '',
    thirdBoxText: 'Third answer',
    thirdBoxAdditonal: '',
  },
  {
    question: 'Third question',
    firstBoxText: ' First answer',
    firstBoxAdditonal: '',
    secondBoxText: ' Second answer',
    secondBoxAdditonal: '',
    thirdBoxText: 'Third answer',
    thirdBoxAdditonal: '',
  },
  {
    question: 'Forth question',
    firstBoxText: ' First answer',
    firstBoxAdditonal: '',
    secondBoxText: ' Second answer',
    secondBoxAdditonal: '',
    thirdBoxText: 'Third answer',
    thirdBoxAdditonal: '',
  },
];

const Questionnaire = () => {
  const [points, setpoints] = useState({ basic: 0, foundation: 0, champion: 0 });
  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [result, setResult] = useState(false);

  const changeQuestionsHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: number) => {
    event.preventDefault();

    if (id === 1) {
      setpoints((prevValues) => {
        return { ...prevValues, basic: points.basic + 1 };
      });
    }
    if (id === 2) {
      setpoints((prevValues) => {
        return { ...prevValues, foundation: points.foundation + 1 };
      });
    }
    if (id === 3) {
      setpoints((prevValues) => {
        return { ...prevValues, champion: points.champion + 1 };
      });
    }

    if (currentQuestion + 1 < questions.length) {
      setcurrentQuestion(currentQuestion + 1);
    } else setResult(true);
  };

  const maxValue = Math.max(...Object.values(points));

  const finalResult = Object.entries(points).find((el) => el[1] === maxValue);

  const currentProgress = (currentQuestion + 1) * (100 / questions.length);

  return (
    <>
      {result ? (
        <div className="quiz__img--container quiz__img--result">
          <div className="quiz__img quiz-result">
            <strong className='quiz-result__text'>{finalResult?.[0]}</strong>
            <AddToCart
              quantity={1}
              productCode={`cyber_security_training_${finalResult![0]}_v1`}
              currency={getCookie('curency') || 'EUR'}
              className="quiz-result__anchor"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="quiz__progress--container">
            <div className="quiz__progress--value" style={{ width: `${currentProgress}%` }}>
              <span>
                Frage {currentQuestion + 1} von {questions.length}
              </span>
            </div>
            <div className="quiz__progress--track" style={{ left: `calc(${currentProgress}% - 1rem)` }}></div>
          </div>
          <p>{questions[currentQuestion].question}</p>
          <div className="quiz__img--container">
            <a href='#' className="quiz__img" onClick={(event) => changeQuestionsHandler(event, 1)}>
              <strong>
                {questions[currentQuestion].firstBoxText}
                <br />
                {questions[currentQuestion].firstBoxAdditonal}
              </strong>
            </a>
            <a href='#' className="quiz__img" onClick={(event) => changeQuestionsHandler(event, 2)}>
              <strong>
                {questions[currentQuestion].secondBoxText}
                <br />
                {questions[currentQuestion].secondBoxAdditonal}
              </strong>
            </a>
            <a href='#' className="quiz__img" onClick={(event) => changeQuestionsHandler(event, 3)}>
              <strong>
                {questions[currentQuestion].thirdBoxText}
                <br />
                {questions[currentQuestion].thirdBoxAdditonal}
              </strong>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default Questionnaire;

