import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useHttp from '../../../hooks/useHttp';
import { Currency } from '../../../types/Order';
import { updateHistoryWithPaymentFrequencyAndCurrency, updatePaymentFrequencyAndCurrency } from './PaymentFrequencies';
import { IPaymentCurrencySwitch, PaymentFrequency } from '../../../types/PaymentFrequency';
import { setPaymentCurrencySwitch } from '../../../store/entities/paymentCurrencySwitch';

const CurrencySelect: React.FC<{ selectedCurrency?: string; paymentFrequency?: PaymentFrequency }> = ({
  selectedCurrency,
  paymentFrequency,
}) => {
  const [currency, setCurrency] = useState<Currency[]>([]);
  const paymentCurrencySwitch = useSelector((state: IPaymentCurrencySwitch) => state.paymentCurrencySwitch);
  const dispatch = useDispatch();

  useHttp(
    { url: '/currency/available-currencies' },
    React.useCallback((data: Currency[]) => {
      dispatch(setPaymentCurrencySwitch({ currency: selectedCurrency, paymentFrequency: paymentFrequency }));
      setCurrency(data);
    }, [dispatch, paymentFrequency, selectedCurrency]),
  );

  const currencyChangeHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    dispatch(setPaymentCurrencySwitch({ currency: event.currentTarget.textContent! }));
    const input = {
      paymentFrequency: paymentCurrencySwitch.paymentFrequency || PaymentFrequency.MONTHLY,
      currency: event.currentTarget.textContent!,
    };

    updatePaymentFrequencyAndCurrency(input);
    updateHistoryWithPaymentFrequencyAndCurrency(input);
  };

  return (
    <>
      {currency.map((el) => (
        <a
          key={el}
          href="#"
          onClick={currencyChangeHandler}
          className={`${paymentCurrencySwitch.currency === el ? 'active' : ''}`}
        >
          {el}
        </a>
      ))}
    </>
  );
};

export default CurrencySelect;

