import { ApiService } from './ApiService';

export const fetchXSRFToken = async () => {
  try {
    const response: string = await ApiService.get('/xsrf/token');
    return response;
  } catch (error) {
    console.error('Failed to get XSRF token:', error);
  }
};
