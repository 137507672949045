import React from 'react';
import { Error, ErrorContextType } from '../types/Errors';

const ErrorContext = React.createContext<ErrorContextType>({
  error: null,
  setError: () => {},
});
export const useError = () => React.useContext(ErrorContext);

export const ErrorProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [error, setError] = React.useState<Error>(null);

  const value: ErrorContextType = {
    error,
    setError,
  };

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};
