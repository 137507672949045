import React, { useEffect } from 'react';

const ProductTableSwitch = () => {
  useEffect(() => {
    const basicLink = document.querySelector('.basicLink');
    const foundationLink = document.querySelector('.foundationLink');
    const championLink = document.querySelector('.championLink');

    basicLink?.addEventListener('click', (event) => {
      event.preventDefault();

      const basic = document.querySelectorAll('.product-details__basic--hidden');
      const foundation = document.querySelectorAll('.product-details__foundation');
      const champion = document.querySelectorAll('.product-details__champion');

      basicLink.classList.add('active');
      foundationLink?.classList.remove('active');
      championLink?.classList.remove('active');

      basic.forEach((el) => (el.className = 'product-details__basic'));
      foundation.forEach((el) => (el.className = 'product-details__foundation--hidden'));
      champion.forEach((el) => (el.className = 'product-details__champion--hidden'));
    });

    foundationLink?.addEventListener('click', (event) => {
      event.preventDefault();

      const foundation = document.querySelectorAll('.product-details__foundation--hidden');
      const basic = document.querySelectorAll('.product-details__basic');
      const champion = document.querySelectorAll('.product-details__champion');

      foundationLink.classList.add('active');
      basicLink?.classList.remove('active');
      championLink?.classList.remove('active');

      foundation.forEach((el) => (el.className = 'product-details__foundation'));
      basic.forEach((el) => (el.className = 'product-details__basic--hidden'));
      champion.forEach((el) => (el.className = 'product-details__champion--hidden'));
    });

    championLink?.addEventListener('click', (event) => {
      event.preventDefault();

      const champion = document.querySelectorAll('.product-details__champion--hidden');
      const foundation = document.querySelectorAll('.product-details__foundation');
      const basic = document.querySelectorAll('.product-details__basic');

      championLink.classList.add('active');
      foundationLink?.classList.remove('active');
      basicLink?.classList.remove('active');

      champion.forEach((el) => (el.className = 'product-details__champion'));
      foundation.forEach((el) => (el.className = 'product-details__foundation--hidden'));
      basic.forEach((el) => (el.className = 'product-details__basic--hidden'));
    });
  }, []);

  return <></>;
};

export default ProductTableSwitch;

