/* eslint-disable @typescript-eslint/no-explicit-any */
import general from './General';
import shoppingCart from './ShoppingCart';
import checkout from './Checkout';
import forms from './Forms';

export const localization: any = {
  'en-US': {
    ...general.en,
    ...shoppingCart.en,
    ...checkout.en,
    ...forms.en,
  },
  'de-CH': {
    ...general.de,
    ...shoppingCart.de,
    ...checkout.de,
    ...forms.de,
  },
};
