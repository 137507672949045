import { CookieType } from '../types/Cookie';
import { dateFormatting } from './dateFormatting';

export const setCookie = (name: string, value: string, expiration: string, path?: string): string => {
  document.cookie = `${name}=${value}; expires=${expiration}; path=${path ? path : '/'}`;
  return document.cookie;
};

export const getCookie = (name: string): string | null => {
  const cookieString: string = document.cookie;
  const cookies: string[] = cookieString.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie: string = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  return null;
};

export const deleteCookie = (name: string, path?: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path ? path : '/'}`;

  return document.cookie;
};

export const checkShoppingCartCookie = (id: string | undefined) => {
  const cookie = getCookie(CookieType.SHOPPING_CART);
  if (id && cookie !== id) setCookie(CookieType.SHOPPING_CART, id, dateFormatting(90, 'days'));
};
