import React, { useEffect } from 'react';

import { getCookie, setCookie } from '../../../lib/cookieUtils';
import { CookieType } from '../../../types/Cookie';

const ToggleCookieBanner = () => {
  useEffect(() => {
    const cookieBanner: HTMLElement = document.querySelector('.cookie-banner')!;
    const cookieBannerBtn: HTMLElement = document.querySelector('.cookie-banner__button')!;

    if (getCookie(CookieType.COOKIE_BANNER)) {
      cookieBanner.classList.add('cookie-banner--hide');
    } else {
      // This is needed because in the server-side rendered part, the banner is hidden by default do avoid flickering
      cookieBanner.classList.remove('cookie-banner--hide');
    }

    cookieBannerBtn.addEventListener('click', () => {
      cookieBanner.classList.add('cookie-banner--hide');
      setCookie(CookieType.COOKIE_BANNER, '0', '0', '/');
    });
  }, []);

  return <></>;
};

export default ToggleCookieBanner;
