const general = {
  en: {
    checkout: 'Checkout',
    invoiceInfo: 'Invoice info',
    differentInvoiceDetails: 'Add other invoice recipient',
    addressDetails: 'Address',
    billingAddressDetails: 'Billing address',
    invoiceRecipient: 'Billing details',
    orderIdentifier: 'Your order identifier (printed on invoice)',
    contactDetails: 'Contact details',
    checkoutOverview: 'Review your order',
    orderConfirmation: 'Order confirmation',
    buyNow: 'Buy Now (obligation to pay)',
    vatReverseChargeUsed: 'VAT Reverse Charge',
    totalNet: 'Total net',
    agreeWith: 'I agree to the',
    thankYou: 'Thank you',
    orderReceived: 'We received your order. We will inform you via email when we processed your order.',
    questionsContact: 'If you have any questions you may contact our support team',
    followOrder: 'Please use the following order number when contacting us',
    emailWithDetails: 'We also sent you an email with your order details.',
    orderTracking: 'Order Tracking',
    orderStatus: 'Order status',
    orderNumber: 'Order number',
  },
  de: {
    checkout: 'Zur Kasse',
    invoiceInfo: 'Rechnungsinfo',
    differentInvoiceDetails: 'Abweichender Rechnungsempfäger',
    addressDetails: 'Adresse',
    billingAddressDetails: 'Rechnungsadresse',
    invoiceRecipient: 'Rechnungsempfänger',
    orderIdentifier: 'Deine Bestellnummer (wird auf Rechnung angezeigt)',
    contactDetails: 'Kontaktdaten',
    checkoutOverview: 'Überprüfe deine Bestellung',
    orderConfirmation: 'Bestellbestätigung',
    buyNow: 'Jetzt kaufen (zahlungspflichtig)',
    vatReverseChargeUsed: 'MwSt. Reverse Charge',
    totalNet: 'Total netto',
    agreeWith: 'Ich stimme den',
    thankYou: 'Danke',
    orderReceived:
      'Wir haben deine Bestellung erhalten. Wir informieren dich per E-Mail, wenn wir deine Bestellung bearbeitet haben.',
    questionsContact: 'Wenn du Fragen hast, darfst du dich gerne an unser Support-Team wenden',
    followOrder: 'Bitte verwende die folgende Bestellnummer, wenn du uns kontaktierst',
    emailWithDetails: 'Wir haben dir auch eine E-Mail mit den Details deiner Bestellung gesendet.',
    orderTracking: 'Sendungsverfolgung',
    orderStatus: 'Status der Bestellung',
    orderNumber: 'Bestellnummer',
  },
};

export default general;
