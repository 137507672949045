import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LanguageContext } from '../providers/LanguageProvider';

const useLanguageFromParams = () => {
  const { setLanguage } = useContext(LanguageContext);
  const { lang } = useParams();
  
  useEffect(() => {
    lang && setLanguage(lang);
  }, [lang, setLanguage]);
};

export default useLanguageFromParams;

