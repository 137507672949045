import { Currency } from '../types/Order';

export const formatCurrency = (amount: number, currency: Currency, hasDecimal: boolean): string => {
  let decimalDelimiter: string;
  let thousandsDelimiter: string;

  switch (currency) {
    case 'EUR':
      decimalDelimiter = ',';
      thousandsDelimiter = ' ';
      break;
    case 'USD':
      decimalDelimiter = '.';
      thousandsDelimiter = ' ';
      break;
    case 'CHF':
      decimalDelimiter = '.';
      thousandsDelimiter = "'";
      break;
    default:
      throw new Error(`Unsupported currency: ${currency}`);
  }

  const parts = parseFloat(amount.toString())
    .toFixed(hasDecimal ? 2 : 0)
    .split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsDelimiter);

  if (parts[1] === '00') {
    parts[1] = '—'; // long dash
  }

  return parts.join(decimalDelimiter);
};
