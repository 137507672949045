import { takeLatest, all } from 'redux-saga/effects';

// handlers
import {
  handleChangeCurrency,
  handleCreateOrder,
  handleGetOrder,
  handleOrderItemQuantity,
  handleGetOrderDetails,
  handleGetOrderLegalTexts,
  handleOrderCheckout,
  handleGetOrderStatus,
  handleGetProductsCount,
  handleAddToCart,
  handleIsSubscribedToShoppingCart,
  handleMatchCountSync,
} from './handlers/orders';
import { handleGetInvoiceInfo, handleUpdateInvoiceInfo } from './handlers/invoice';
import { handleGetData, handleGetOrderDetailsBackOffice, handleRequestChangeOrderStatus } from './handlers/adminOrders';

// actions
import {
  getOrder,
  createOrder,
  changeCurrency,
  itemQuantityChange,
  getOrderDetails,
  getOrderLegalTexts,
  checkoutOrder,
  getOrderStatus,
  getProductsCount,
  addToCart,
  getShoppingCartSubscription,
  matchCountSync,
} from '../entities/order';
import { getInvoiceInfo, updateInvoiceInfo } from '../entities/invoice';
import { getData } from '../entities/adminOrders';
import { changeOrderStatus, getOrderDetailsBackOffice } from '../entities/orderDetailsBackOffice';

export function* watcherSaga() {
  yield all([
    takeLatest(getOrder, handleGetOrder),
    takeLatest(createOrder, handleCreateOrder),
    takeLatest(changeCurrency, handleChangeCurrency),
    takeLatest(itemQuantityChange, handleOrderItemQuantity),
    takeLatest(getInvoiceInfo, handleGetInvoiceInfo),
    takeLatest(updateInvoiceInfo, handleUpdateInvoiceInfo),
    takeLatest(getOrderDetails, handleGetOrderDetails),
    takeLatest(getOrderLegalTexts, handleGetOrderLegalTexts),
    takeLatest(checkoutOrder, handleOrderCheckout),
    takeLatest(getOrderStatus, handleGetOrderStatus),
    takeLatest(getProductsCount, handleGetProductsCount),
    takeLatest(addToCart, handleAddToCart),
    takeLatest(getShoppingCartSubscription, handleIsSubscribedToShoppingCart),
    takeLatest(getData, handleGetData),
    takeLatest(getOrderDetailsBackOffice, handleGetOrderDetailsBackOffice),
    takeLatest(changeOrderStatus, handleRequestChangeOrderStatus),
    takeLatest(matchCountSync, handleMatchCountSync),
  ]);
}
