import { ApiService } from '../../../services/ApiService';
import { Invoice } from '../../../types/Invoice';

export const requestGetInvoiceInfo = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/invoice-info`);
};

export const requestUpdateInvoiceInfo = (payload: { orderId: string; data: Invoice }) => {
  return ApiService.put(`/orders/${payload.orderId}/invoice-info`, payload.data);
};
