import { ApiService } from '../../../services/ApiService';
import { ItemQuantity } from '../../../types/Order';
import { ICheckoutOrder, ICreateOrder, IUpdateOrder } from '../../../types/OrderPayloads';

export const requestGetOrder = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/shopping-cart/`);
};

export const requestCreateOrder = (payload: ICreateOrder) => {
  return ApiService.post(`/orders/`, payload);
};

export const requestChangeCurrency = (payload: { orderId: string; currency: string }) => {
  return ApiService.put(`/orders/${payload.orderId}/shopping-cart/currency`, { currency: payload.currency });
};

export const requestOrderItemQuantity = (payload: ItemQuantity) => {
  return ApiService.put(`/orders/${payload.orderId}/shopping-cart/${payload.itemId}`, {
    productCode: payload.productCode,
    quantity: payload.quantity,
    status: payload.status,
    paymentFrequency: payload.paymentFrequency,
  });
};

export const requestGetOrderDetails = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/detail`);
};

export const requestGetOrderLegalTexts = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/legal-texts`);
};

export const requestOrderCheckout = (payload: ICheckoutOrder) => {
  return ApiService.post(`/orders/${payload.orderId}/checkout`, {
    agreeWithLegalTexts: payload.agreedLegalTexts,
    captchaSolution: payload.captchaSolution,
    language: payload.language,
  });
};

export const requestOrderStatus = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/status-info`);
};

export const requestProductsCount = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/shopping-cart/products-count`);
};

export const requestAddToCart = (payload: IUpdateOrder) => {
  return ApiService.post(`/orders/${payload.orderId}/shopping-cart/`, {
    productCode: payload.productCode,
    quantity: payload.quantity,
    currency: payload.currency,
    language: payload.language,
    paymentFrequency: payload.paymentFrequency,
  });
};

export const requestIsSubscribed = (orderId: string) => {
  return ApiService.get(`/orders/${orderId}/is-subscribed`);
};

export const requestMatchCountSync = (payload: { orderId: string; itemId: string }) => {
  return ApiService.post(`/orders/${payload.orderId}/shopping-cart/${payload.itemId}/match-count`);
};
