export const captchaUrl = (formType: string | undefined) => {
  switch (formType) {
    case 'contact':
      return '/contact-form/site-key';
    case 'sign-in':
      return '/users/captcha/site-key';
    case 'checkout':
      return '/orders/captcha/site-key';
    default:
      return '/captcha/site-key';
  }
};