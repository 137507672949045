import { all, call, put } from 'redux-saga/effects';
import {
  requestChangeCurrency,
  requestGetOrder,
  requestOrderItemQuantity,
  requestGetOrderDetails,
  requestGetOrderLegalTexts,
  requestOrderCheckout,
  requestOrderStatus,
  requestProductsCount,
  requestAddToCart,
  requestCreateOrder,
  requestIsSubscribed,
  requestMatchCountSync,
} from '../requests/orders';
import {
  setOrder,
  setOrderDetails,
  setOrderError,
  setOrderLegalTexts,
  setOrderStatus,
  setProductsCount,
  setShoppingCardSubscription,
} from '../../entities/order';
import { Currency, ItemQuantity, LegalText, Order, OrderDetails, OrderItem } from '../../../types/Order';
import { ICreateOrder, IHandleCheckout, IUpdateOrder } from '../../../types/OrderPayloads';
import { CookieType } from '../../../types/Cookie';
import { setInvoiceInfo, setStep } from '../../entities/invoice';
import { deleteCookie, setCookie } from '../../../lib/cookieUtils';
import { dateFormatting } from '../../../lib/dateFormatting';
import { setDefaultInvoiceAddress } from '../../../lib/invoiceInfoMapper';

const setCurrencyCookie = (currency: Currency) => {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  setCookie(CookieType.CURRENCY, currency, oneYearFromNow.toUTCString());
};

export function* handleGetOrder(action: { payload: string }): Generator<object, void, { data: Order }> {
  try {
    const response = yield call(requestGetOrder, action.payload);
    yield put(setOrder({ ...response }));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleMatchCountSync(action: {
  payload: { orderId: string; itemId: string };
}): Generator<object, void, Order> {
  try {
    const response = yield call(requestMatchCountSync, action.payload);
    yield put(setOrder({ ...response }));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleCreateOrder(action: { payload: ICreateOrder }): Generator<object, void, Order> {
  try {
    const response = yield call(requestCreateOrder, action.payload);
    setCookie(CookieType.SHOPPING_CART, response.id, dateFormatting(90, 'days'));
    setCurrencyCookie(response.currency);
    yield all([put(setOrder({ ...response })), put(setProductsCount(1))]);
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleChangeCurrency(action: {
  payload: { orderId: string; currency: string };
}): Generator<object, void, Order> {
  try {
    const response = yield call(requestChangeCurrency, action.payload);
    setCurrencyCookie(response.currency);
    yield put(setOrder({ ...response }));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleOrderItemQuantity(action: { payload: ItemQuantity }): Generator<object, void, { data: Order }> {
  try {
    const response = yield call(requestOrderItemQuantity, action.payload);
    if (response) {
      const countResponse = yield call(requestProductsCount, action.payload.orderId);
      yield put(setProductsCount(countResponse));
    }
    yield put(setOrder({ ...response }));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleGetOrderDetails(action: { payload: string }): Generator<object, void, OrderDetails> {
  try {
    const response = yield call(requestGetOrderDetails, action.payload);
    if (!response.invoiceInfo.invoiceAddress) {
      response.invoiceInfo.invoiceAddress = setDefaultInvoiceAddress(response.invoiceInfo.address);
    }
    yield all([put(setInvoiceInfo({ ...response.invoiceInfo })), put(setOrderDetails({ ...response }))]);
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleGetOrderLegalTexts(action: { payload: string }): Generator<object, void, { data: LegalText[] }> {
  try {
    const response = yield call(requestGetOrderLegalTexts, action.payload);
    yield put(setOrderLegalTexts(response));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleOrderCheckout(action: {
  payload: IHandleCheckout;
}): Generator<object, void, { data: OrderDetails }> {
  try {
    const response = yield call(requestOrderCheckout, action.payload);
    deleteCookie(CookieType.SHOPPING_CART);
    yield all([put(setOrderDetails({ ...response })), put(setStep(action.payload.step + 1)), put(setProductsCount(0))]);
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleGetOrderStatus(action: { payload: string }): Generator<object, void, { data: string }> {
  try {
    const response = yield call(requestOrderStatus, action.payload);
    yield put(setOrderStatus(response));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleGetProductsCount(action: { payload: string }): Generator<object, void, { data: number }> {
  try {
    const response = yield call(requestProductsCount, action.payload);
    yield put(setProductsCount(response));
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleAddToCart(action: { payload: IUpdateOrder }): Generator<object, void, { data: OrderItem }> {
  try {
    const response = yield call(requestAddToCart, action.payload);
    if (response) {
      const countResponse = yield call(requestProductsCount, action.payload.orderId);
      yield put(setProductsCount(countResponse));
    }
  } catch (error) {
    yield put(setOrderError(error));
  }
}

export function* handleIsSubscribedToShoppingCart(action: { payload: string }): Generator<object, void, boolean> {
  try {
    const response = yield call(requestIsSubscribed, action.payload);
    yield put(setShoppingCardSubscription(response));
  } catch (error) {
    console.log(error);
  }
}
