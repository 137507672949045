import React, { useContext } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { localization } from '../../../../assets/Localization';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { Gender, ExperienceLevel } from '../../../../types/FormSendingData';

const NewsletterInfoRadioButton: React.FC<{
  id: string;
  localizationText: string;
  enumObject: typeof Gender | typeof ExperienceLevel;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: string | undefined;
  className?: string;
}> = ({ id, localizationText, enumObject, onChange, isChecked, className }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Box className="newsletter-form__radio">
      <FormControl>
        <FormLabel id={id}>{localization[language][localizationText]}</FormLabel>
        <RadioGroup aria-labelledby={id} className={` ${className}`}>
          {Object.values(enumObject).map((el) => (
            <FormControlLabel
              key={el}
              value={el}
              control={<Radio onChange={onChange} checked={isChecked === el ? true : false} />}
              label={localization[language][el.toLowerCase()]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default NewsletterInfoRadioButton;

