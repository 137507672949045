/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorType } from '../types/Errors';

export const mapErrorResponse = (error: ErrorType) => {
  const mappedErrors: any = {};

  if (error) {
    error.errors?.forEach((e) => {
      const fieldParts = e.field.split('.');
      let currentObj = mappedErrors;
      for (let i = 0; i < fieldParts.length; i++) {
        const field = fieldParts[i];
        if (!currentObj[field]) {
          currentObj[field] = {};
        }
        currentObj = currentObj[field];
        if (i === fieldParts.length - 1) {
          currentObj.messagesByLanguage = e.messagesByLanguage;
        }
      }
    });
  }

  return mappedErrors;
};
