import { call, put } from 'redux-saga/effects';
import { setData, deleteData } from '../../entities/adminOrders';
import { GetDataType, OrderDetailType, OrdersResponseType, ChangeOrderStatusType } from '../../../types/AdminOrders';
import { requestChangeOrderStatus, requestGetData, requestGetOrderDetailsBackOffice } from '../requests/adminOrders';
import { setOrderDetailsBackOffice } from '../../entities/orderDetailsBackOffice';

export const sanitizePayload = (payload: GetDataType) => {
  return {
    sortDirection: payload.sortDirection || 'ASC',
    statuses: payload.statuses || [],
    company: payload.company || '',
    number: payload.number || '',
    dateRange: payload.dateRange || {},
  };
};

export function* handleGetData(action: { payload: GetDataType }): Generator<object, void, OrdersResponseType> {
  try {
    const response = yield call(requestGetData, action.payload);
    const sanitized = sanitizePayload(action.payload);
    yield put(
      setData({
        ...response,
        ...sanitized,
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetOrderDetailsBackOffice(action: {
  payload: string;
}): Generator<object, void, OrderDetailType> {
  try {
    const response = yield call(requestGetOrderDetailsBackOffice, action.payload);
    yield put(
      setOrderDetailsBackOffice({
        ...response,
      }),
    );
  } catch (error) {
    console.log(error);
  }
}

export function* handleRequestChangeOrderStatus(action: {
  payload: ChangeOrderStatusType;
}): Generator<object, void, OrderDetailType> {
  try {
    const actions = yield call(requestChangeOrderStatus, action.payload);
    if (actions) {
      const response = yield call(requestGetOrderDetailsBackOffice, action.payload.id);
      yield put(
        setOrderDetailsBackOffice({
          ...response,
        }),
      );
      return;
    }
    yield put(deleteData(action.payload.id));
  } catch (error) {
    console.log(error);
  }
}

