import React from 'react';
import Form from '../../common/Form';
import ModalType from '../../../types/Modal';
import { useSubscription } from '../../../hooks/useSubscription';

const Downloadable: React.FC<ModalType> = (props) => {
  const { sendData, confirmationMessage } = useSubscription();

  return (
    <div className="downloadable-form">
      <Form
        title="Download"
        onSendingData={(data) => sendData(`${props.href}`, data)}
        additional="download"
        confirmationMessage={confirmationMessage}
      />
      <a onClick={props.onClick} className="downloadable-form__close">
        <span></span>
        <span></span>
      </a>
    </div>
  );
};

export default Downloadable;
