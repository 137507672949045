import React from 'react';
import { Loader } from '../Loader';
import { Typography } from '@mui/material';

const SuccessMessage = (props: { additional?: string; confirmationMessage?: string }) => {
  if (props.confirmationMessage)
    return (
      <Typography variant="h2" sx={{ fontSize: '1.5rem' }} className="newsletter-title">
        {props.confirmationMessage}
      </Typography>
    );
  // if (!props.confirmationMessage) return <h1 className="newsletter-title">{localization[language].generalSuccessMessage}</h1>;

  return <Loader />;
};

export default SuccessMessage;

