type FormSendingDData = {
  name?: string;
  email?: string;
  solution: string;
  isAgreed: boolean;
  language: string;
  lastname?: string;
  phoneCode?: string;
  phone?: string;
  isSubscribed?: boolean;
  message?: string;
  page?: number;
  itemsPerPage?: number;
  sortColumn?: string;
  sortDirection?: string;
  statuses?: string[];
  dateRange?: {
    fromInclusive?: string;
    toInclusive?: string;
  };
  company?: string;
  number?: string;
  gender?: Gender;
  recipient?: Recipient,
  subject?: string
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Role {
  EXECUTIVE = 'EXECUTIVE',
  CISO = 'CISO',
  INFORMATION_SECURITY_OFFICER = 'INFORMATION_SECURITY_OFFICER',
  HEAD_OF_IT = 'HEAD_OF_IT',
  DEPARTMENT_TEAM_LEAD = 'DEPARTMENT_TEAM_LEAD',
  CYBER_SECURITY_EXPERT = 'CYBER_SECURITY_EXPERT',
  SOFTWARE_DEVELOPER = 'SOFTWARE_DEVELOPER',
  IT_OPERATOR = 'IT_OPERATOR',
  IT_ARCHITECT = 'IT_ARCHITECT',
  ENTERPRISE_ARCHITECT = 'ENTERPRISE_ARCHITECT',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  TRAINER = 'TRAINER',
  FREELANCER = 'FREELANCER',
  OTHER = 'OTHER',
}

export enum ExperienceLevel {
  NONE = 'NONE',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  EXPERT = 'EXPERT',
}

export type NewsletterInfoData = {
  name: string;
  language: string;
  lastname?: string;
  companyName?: string;
  role?: Role;
  expLvl?: ExperienceLevel;
  iWantTo?: number[];
  gender?: Gender;
};

export type SubscriptionInfoType =
  | {
      firstName: string;
      lastName?: string;
      preferredLanguage: string;
      gender?: Gender;
      companyName?: string;
      role?: Role;
      experienceLevel?: ExperienceLevel;
      topicIds?: number[];
      status?: string;
      newsletterEnabled?: boolean;
    }
  | undefined;

export default FormSendingDData;

export interface ApiData {
  name?: string;
  email?: string;
  solution?: string;
  isAgreed?: boolean;
  language?: string;
  lastname?: string;
  phone?: string;
  isSubscribed?: boolean;
  phoneCode?: string;
  companyName?: string;
  role?: Role;
  expLvl?: ExperienceLevel;
  iWantTo?: number[];
  gender?: Gender;
}

export enum Recipient {
  MESSAGE_CENTRAL = 'MESSAGE_CENTRAL',
  EDUCATION_EXPERT = 'EDUCATION_EXPERT',
  DATA_PRIVACY_OFFICER = 'DATA_PRIVACY_OFFICER',
  INFORMATION_SECURITY_OFFICER_SECURITY_ISSUES_ONLY = 'INFORMATION_SECURITY_OFFICER_SECURITY_ISSUES_ONLY'
}

export type Method = 'get' | 'put' | 'post' | 'delete';
