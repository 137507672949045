import { useContext, useState } from 'react';
import { ConfirmationMessageType } from '../types/RESTData';
import { ApiService } from '../services/ApiService';
import { LanguageContext } from '../providers/LanguageProvider';
import { ApiData, Method } from '../types/FormSendingData';

export const useSubscription = () => {
  const { language: defaultLanguage } = useContext(LanguageContext);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');

  const sendData = async (endpoint: string, data: ApiData, dispatchFunction?: () => void, method?: Method) => {
    const {
      name,
      email,
      solution,
      isAgreed,
      language,
      lastname,
      phone,
      isSubscribed,
      phoneCode,
      companyName,
      role,
      expLvl,
      iWantTo,
      gender,
    } = data;

    const payload = {
      firstName: name,
      lastName: lastname,
      email,
      captchaSolution: solution,
      agreeWithDataPrivacy: isAgreed,
      preferredLanguage: language || defaultLanguage,
      newsletterEnabled: isSubscribed,
      phoneNumber: phone,
      phoneCountryCode: phoneCode,
      companyName,
      role,
      experienceLevel: expLvl,
      topicIds: iWantTo,
      gender,
    };

    try {
      const res: ConfirmationMessageType = await ApiService[method || 'post'](endpoint, payload);
      if (res.messages) setConfirmationMessage(res.messages[0].translations[language || defaultLanguage]);
      setTimeout(() => {
        if (res && dispatchFunction) {
          dispatchFunction();
        }
      }, 0);
    } catch (error) {
      console.error(error);
    }
  };

  return { sendData, confirmationMessage };
};
