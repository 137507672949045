import { LanguageType, Languages } from '../types/LanguageContext';

type TimeUnit = 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';

export const dateFormatting = (value: number, unit: TimeUnit): string => {
  const date = new Date();

  switch (unit) {
    case 'minutes':
      date.setMinutes(date.getMinutes() + value);
      break;
    case 'hours':
      date.setHours(date.getHours() + value);
      break;
    case 'days':
      date.setDate(date.getDate() + value);
      break;
    case 'weeks':
      date.setDate(date.getDate() + 7 * value);
      break;
    case 'months':
      date.setMonth(date.getMonth() + value);
      break;
    case 'years':
      date.setFullYear(date.getFullYear() + value);
      break;
    default:
      throw new Error('Invalid time unit');
  }

  return date.toUTCString();
};

export const calculateTimeZone = (timestamp: string) => {
  const utcZone = timestamp?.split('T')[1].split('+')[1] || '';
  const timezoneOptions: Intl.DateTimeFormatOptions = { timeZoneName: 'short', hour12: false };
  const dateStringParsing = new Date(timestamp);
  const timeZoneShortName = dateStringParsing.toLocaleString('en-DK', timezoneOptions).split('00 ')[1];

  return { timeZoneShortName, utcZone };
};

export const universalDateTimeFormat = (timestamp?: string) => {
  const date = timestamp?.split('T')[0] || '';
  const time = timestamp?.split('T')[1].split('+')[0].slice(0, -3) || '';
  const utcZone = timestamp?.split('T')[1].split('+')[1] || '';
  return { date, time, utcZone };
};

export const calculateIntlDateAndTIme = (language: LanguageType, dateTimeString?: string) => {
  if (!dateTimeString) return;

  const options: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
    timeStyle: 'short',
  };

  const { timeZoneShortName, utcZone } = calculateTimeZone(dateTimeString);

  if (language === Languages.EN) {
    const { date, time, utcZone } = universalDateTimeFormat(dateTimeString);
    return `${date} ${time} ${timeZoneShortName} (UTC+${utcZone})`;
  }

  const dateStringParsing = new Date(dateTimeString);
  return `${new Intl.DateTimeFormat(language, options).format(
    dateStringParsing,
  )} ${timeZoneShortName} (UTC+${utcZone})`;
};
