import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import classes from './customModal.module.css';

interface ModalProps {
  open: boolean;
  title?: string;
  content: React.ReactNode;
  onClose: () => void;
}

const CustomModal: React.FC<ModalProps> = ({ open, title, content, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} disableScrollLock={true} sx={{ '.MuiDialog-paper': { margin: 0 } }}>
      <div className={classes['modal-wrapper']}>
        <DialogTitle>{title || ''}</DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default React.memo(CustomModal);
