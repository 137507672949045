/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState, useContext } from 'react';
import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import Captcha from '../Captcha';
import InputText from './InputText';
import AgreeTerms from './AgreeTerms';
import PhoneText from './PhoneText';
import SuccessMessage from './SuccessMessage';
import FormButton from './FormButton';
import CountryPhoneForm from './CountryPhoneForm';
import useSiteKey from '../../../hooks/useSiteKey';
import useContactForm from '../../../hooks/useContactForm';
import useLanguageFromParams from '../../../hooks/useLanguageFromParams';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { captchaUrl } from '../../../lib/CaptchaUrl';
import FormSendingDData from '../../../types/FormSendingData';
import { ErrorType } from '../../../types/Errors';
import { localization } from '../../../assets/Localization';

// NOTE: Serves as the base component for various forms in the application
const Form = (props: {
  title?: string;
  errors?: string[];
  additional?: string;
  confirmationMessage?: string;
  text?: string;
  img?: JSX.Element;
  formErrors?: ErrorType;
  mappedErrors?: any;
  setMappedErrors?: React.Dispatch<any>;
  onSendingData: (data: FormSendingDData) => void;
}) => {
  const { language, supportedLanguages } = useContext(LanguageContext);
  const [solution, setSolution] = useState('');
  const [termsIsChecked, setTermsIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [errors, setErrors] = useState<{
    [key: string]: {
      phone: string;
    };
  }>({
    'en-US': { phone: '' },
    'de-CH': { phone: '' },
  });
  const siteKey = useSiteKey(captchaUrl(props.additional));
  useLanguageFromParams();

  const {
    company,
    companyField,
    gender,
    genderField,
    message,
    messageField,
    recipient,
    recipientField,
    subject,
    subjectField,
  } = useContactForm({
    additional: props.additional,
    mappedErrors: props.mappedErrors,
    setMappedErrors: props.setMappedErrors,
  });

  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const subscribeInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  let lastName = <></>;
  let phoneNumber = <></>;
  let subscribe = <></>;
  let popup = <></>;

  const solutionHandler = useCallback((data: string) => {
    setSolution(data);
  }, []);

  const agreeWithTermsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsIsChecked(event.target.checked);
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    props.onSendingData({
      name: nameInputRef.current?.value,
      email: emailInputRef.current?.value,
      solution,
      isAgreed: termsIsChecked,
      language,
      lastname: lastNameInputRef.current?.value,
      phone: phoneInputRef.current?.value,
      phoneCode: selectedCountry,
      isSubscribed: subscribeInputRef.current?.checked,
      message,
      gender,
      company,
      subject,
      recipient,
    });
  };

  if (props.additional === 'webinar' || props.additional === 'contact') {
    lastName = (
      <InputText
        type="text"
        title={localization[language].lastNameOptional}
        id="last-name"
        isRequired={false}
        ref={lastNameInputRef}
      />
    );

    phoneNumber = (
      <Box className="newsletter-form__name">
        <CountryPhoneForm ref={phoneInputRef} selectCountry={setSelectedCountry} phoneErrors={setErrors} />
        <PhoneText additional={props.additional} />
      </Box>
    );
  }

  if (props.additional === 'webinar') {
    subscribe = (
      <>
        <Box className="newsletter-form__agree">
          <FormControlLabel
            control={<Checkbox id="newsletter-subscribe" inputRef={subscribeInputRef} />}
            label={localization[language].subscribeToNewsletter}
          />
          <Typography variant="caption">{localization[language].subscribeMessage}</Typography>
        </Box>
      </>
    );
  }

  if (props.additional === 'exit-popup' || props.additional === 'shopping-cart') {
    popup = (
      <>
        <Typography className="exit-popup__text">{props.text?.split('\n')[0]}</Typography>
        <Typography className="exit-popup__text" sx={{ marginTop: '0.5rem' }}>
          {props.text?.split('\n')[1]}
        </Typography>
        {props.img}
      </>
    );
  }

  return (
    <>
      {!props.confirmationMessage && (
        <>
          <Typography variant="h2" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }} className="newsletter-title">
            {props.title}
          </Typography>
          <form className="newsletter-form" onSubmit={submitHandler}>
            {genderField}
            {props.additional !== 'exit-popup' && props.additional !== 'shopping-cart' && (
              <InputText
                id="first-name"
                type="text"
                isRequired={true}
                title={localization[language].firstName}
                ref={nameInputRef}
              />
            )}
            {popup}
            {lastName}
            {companyField}
            <InputText
              id="email"
              type="email"
              isRequired={true}
              title={localization[language].emailAddress}
              ref={emailInputRef}
            />
            {
              <Box component="span" className="newsletter-form__errors">
                {errors[language].phone}
              </Box>
            }
            {phoneNumber}
            {recipientField}
            {subjectField}
            {messageField}
            {subscribe}
            {props.additional !== 'contact' && <AgreeTerms onChange={agreeWithTermsHandler} />}
            {props.additional === 'contact' && (
              <AgreeTerms
                checkboxText={
                  language === supportedLanguages[0] ? (
                    <>
                      Please contact me to answer my request. I read and accept the{' '}
                      <Link href="/web/en-US/privacy-policy" sx={{ textDecoration: 'none' }}>
                        privacy policy
                      </Link>
                      .
                    </>
                  ) : (
                    <>
                      Bitte kontaktiert mich, um meine Anfrage zu beantworten. Ich habe die{' '}
                      <Link href="/web/de-CH/privacy-policy" sx={{ textDecoration: 'none' }}>
                        Datenschutzerklärung
                      </Link>{' '}
                      gelesen und akzeptiere diese.
                    </>
                  )
                }
                onChange={agreeWithTermsHandler}
              />
            )}
            <Captcha
              onSolution={solutionHandler}
              siteKey={siteKey}
              mappedErrors={props.mappedErrors}
              setMappedErrors={props.setMappedErrors}
            />
            <FormButton solution={solution} termsIsChecked={termsIsChecked} additional={props.additional} />
          </form>
        </>
      )}

      {props.confirmationMessage && (
        <SuccessMessage additional={props.additional} confirmationMessage={props.confirmationMessage} />
      )}
    </>
  );
};

export default Form;

