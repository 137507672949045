import React, { useContext } from 'react';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { Box, Checkbox, FormControlLabel, Link } from '@mui/material';

type AgreeTermType = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checkboxText?: JSX.Element;
};

const AgreeTerms = (props: AgreeTermType) => {
  const { language, supportedLanguages } = useContext(LanguageContext);

  let { checkboxText } = props;

  if (!checkboxText) {
    if (language === supportedLanguages[0])
      checkboxText = (
        <>
          I agree to receive promotional emails from CYRINAS AG. I can unsubscribe any time. I read and agree to the{' '}
          <Link href="/web/en-US/privacy-policy" sx={{ textDecoration: 'none' }}>
            privacy policy
          </Link>
          .
        </>
      );

    if (language === supportedLanguages[1])
      checkboxText = (
        <>
          Ich bin einverstanden von der CYRINAS AG werbliche E-Mails zu erhalten. Ich kann mich jederzeit abmelden. Ich
          habe die{' '}
          <Link href="/web/de-CH/privacy-policy" sx={{ textDecoration: 'none' }}>
            Datenschutzerklärung
          </Link>{' '}
          gelesen und stimme ihr zu.
        </>
      );
  }

  return (
    <Box className="newsletter-form__agree">
      <FormControlLabel
        control={<Checkbox id="agree-terms" onChange={props.onChange} required />}
        label={checkboxText}
      />
    </Box>
  );
};

export default AgreeTerms;

