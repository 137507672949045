import React from 'react';
import { RouterSuffix } from '../../../types/RouterSuffix';

/**
 * This component is used in Thymeleaf to redirect user to React newsletter settings page,
 * if he has JS enabled
 */
const NewsletterInfoRedirect: React.FC<{ token: string }> = ({ token}) => {
  window.location.href = `/${RouterSuffix.APP}/newsletter/${token}/`;

  return <></>;
};

export default NewsletterInfoRedirect;

