import React, { useContext, useState } from 'react';
import Form from '../../common/Form';
import { localization } from '../../../assets/Localization';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { useSubscription } from '../../../hooks/useSubscription';
import CustomModal from '../../common/CustomModal';

const Newsletter = () => {
  const { sendData, confirmationMessage } = useSubscription();
  const { language } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);

  const newsletterHandler = () => {
    setShowModal((prevValue) => !prevValue);
  };

  return (
    <>
      <a href="#" className="footer__cta" onClick={newsletterHandler}>
        {localization[language].newsletterTitle}
      </a>
      {showModal && (
        <CustomModal
          content={
            <Form
              title={localization[language].newsletterTitle}
              onSendingData={(data) => sendData(`/newsletter/`, data)}
              additional="newsletter"
              confirmationMessage={confirmationMessage}
            />
          }
          onClose={newsletterHandler}
          open
        />
      )}
    </>
  );
};

export default Newsletter;
