const general = {
  en: {
    next: 'Next',
    back: 'Back',
    tryAgain: 'Try Again',
    firstName: 'First name',
    lastName: 'Last name',
    lastNameOptional: 'Last name (optional)',
    address: 'Address',
    email: 'Email',
    gender: 'Gender',
    genderOptional: 'Gender (optional)',
    phone: 'Phone',
    city: 'City',
    zipCode: 'Zip / Postal code',
    country: 'Country',
    state: 'State/Canton/Province/Region',
    companyName: 'Company Name',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    change: 'Change',
    close: 'Close',
    seat: 'seat',
    seats: 'seats',
    month: 'month',
    months: 'months',
    monthly: 'monthly',
    perMonthPerSeat: 'per month per seat',
    oneTime: 'one time',
    training: 'training',
    trainings: 'trainings',
    replay: 'Replay',
    webinarColumnTitle: 'Title',
    code: 'Short Name',
    dateTime: 'Date And Time',
    duration: 'Duration (in minutes)',
    subscribers: 'Subscribers',
    areYouSure: 'Are you sure?',
    areYouSureWantToDeleteWebinar:
      'Are you sure you want to delete the webinar? You should send a cancellation email to all subscribers before you delete the webinar.',
    delete: 'Delete',
    create: 'Create',
    link: 'Link',
    description: 'Description',
    save: 'Save',
    subscriptionCodePattern: "Only uppercase and lowercase letters, numbers, and dashes (“-”) are allowed"
  },
  de: {
    next: 'Weiter',
    back: 'Zurück',
    tryAgain: 'Versuche es erneut',
    firstName: 'Vorname',
    lastName: 'Nachname',
    lastNameOptional: 'Nachname (optional)',
    address: 'Adresse',
    email: 'Email',
    gender: 'Geschlecht',
    genderOptional: 'Geschlecht (optional)',
    phone: 'Telefon',
    city: 'Stadt',
    zipCode: 'Postleitzahl',
    country: 'Land',
    state: 'Staat/Kanton/Provinz/Region',
    companyName: 'Firma',
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Divers',
    change: 'Ändern',
    close: 'Schliessen',
    seat: 'Seat',
    seats: 'Seats',
    month: 'Monat',
    months: 'Monate',
    monthly: 'monatlich',
    perMonthPerSeat: 'pro Monat pro Seat',
    oneTime: 'einmal',
    training: 'Training',
    trainings: 'Trainings',
    replay: 'Wiederholung',
    webinarColumnTitle: 'Titel',
    code: 'Code',
    dateTime: 'Datum und Uhrzeit',
    duration: 'Dauer (in Minuten)',
    subscribers: 'Teilnehmende',
    areYouSure: 'Bist du sicher?',
    areYouSureWantToDeleteWebinar:
      'Bist du sicher, dass du das Webinar löschen möchtest? Du solltest eine E-Mail mit einer Absage an alle Teilnehmenden schicken, bevor du das Webinar löscht',
    delete: 'Löschen',
    create: 'Erstellen',
    link: 'Verknüpfung',
    description: 'Beschreibung',
    save: 'Speichern',
    subscriptionCodePattern: "Es sind nur Groß- und Kleinbuchstaben, Zahlen und Bindestriche („-“) zulässig"
  },
};

export default general;

