import { ApiService } from '../../../services/ApiService';
import { Action, GetDataType, ChangeOrderStatusType } from '../../../types/AdminOrders';

export const requestGetData = (payload: GetDataType) => {
  return ApiService.post('/orders/admin/list', payload);
};

export const requestGetOrderDetailsBackOffice = (id: string) => {
  return ApiService.get(`/orders/admin/${id}/`);
};

export const requestChangeOrderStatus = (payload: ChangeOrderStatusType) => {
  if (payload.action === Action.PAY)
    return ApiService.post(`/orders/admin/${payload.id}/pay`, { amount: payload.amount, date: payload.availableFrom });

  if (payload.action === Action.CANCEL_REFUND)
    return ApiService.post(`/orders/admin/${payload.id}/cancel-refund`, {
      refund: payload.refund,
      refundAmount: payload.refundAmount,
    });

  if (payload.action === Action.ARCHIVE_REFUND)
    return ApiService.post(`/orders/admin/${payload.id}/archive-refund`, {
      refundDate: payload.refundDate,
    });

  if (payload.action === Action.START_TERMINATION)
    return ApiService.post(`/orders/admin/${payload.id}/termination`, {
      refund: payload.refund,
      refundAmount: payload.refundAmount,
    });

  if (payload.action === Action.DELETE) return ApiService.delete(`/orders/admin/${payload.id}/`);

  return ApiService.post(`/orders/admin/${payload.id}/status`, { action: payload.action });
};

