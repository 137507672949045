import { createSlice } from '@reduxjs/toolkit';

const invoice = createSlice({
  name: 'invoice',
  initialState: {
    invoiceLoading: false,
    invoiceInfo: null,
    error: null,
    step: 0,
    invoiceData: null,
  },
  reducers: {
    getInvoiceInfo(state, _action) {
      return { ...state, invoiceLoading: true };
    },
    setInvoiceInfo(state, action) {
      const data = action.payload;
      if (!data.address.countryCode && data.address.country) {
        data.address.countryCode = data.address.country.code;
      }
      if (!data.invoiceAddress.countryCode && data.invoiceAddress.country) {
        data.invoiceAddress.countryCode = data.invoiceAddress.country.code;
      }
      return { ...state, invoiceInfo: data, invoiceData: data, invoiceLoading: false };
    },
    setStep(state, action) {
      const data = action.payload;
      return { ...state, step: data, invoiceLoading: false };
    },
    setInvoiceError(state, action) {
      const data = action.payload;
      return { ...state, error: data, invoiceLoading: false };
    },
    updateInvoiceInfo(state, _action) {
      return { ...state, invoiceLoading: true };
    },
    setInvoiceData(state, action) {
      const data = action.payload;
      return { ...state, invoiceData: data };
    },
  },
});

export const { getInvoiceInfo, setInvoiceInfo, setInvoiceError, updateInvoiceInfo, setStep, setInvoiceData } =
  invoice.actions;

export default invoice.reducer;
