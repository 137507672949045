import React, { useState } from 'react';
import Downloadable from './index';
import CustomModal from '../../common/CustomModal';

const DownloadableContentButton: React.FC<{ code: string }> = ({ code }) => {
  const [showModal, setShowModal] = useState(false);

  const downloadHandler = () => {
    setShowModal((prevValue) => !prevValue);
  };

  return (
    <>
      <a href="#" onClick={downloadHandler}>
        call to action 1<br />
        Leitfaden herunterladen
      </a>
      {showModal && (
        <CustomModal
          open
          onClose={downloadHandler}
          content={<Downloadable onClick={downloadHandler} href={`/downloadables/by-code/${code}/subscriptions/`} />}
        />
      )}
    </>
  );
};

export default DownloadableContentButton;

